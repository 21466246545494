<template>
  <modal :show.sync="show" :on-close="onClose">
    <div class="column">
      <woot-modal-header :header-title="pageTitle" />
      <form class="row medium-8" @submit.prevent="editCannedResponse()">
        <div class="medium-12 columns">
          <label :class="{ error: $v.shortCode.$error }">
            {{ $t('CANNED_MGMT.EDIT.FORM.SHORT_CODE.LABEL') }}
            <input
              v-model.trim="shortCode"
              type="text"
              :placeholder="$t('CANNED_MGMT.EDIT.FORM.SHORT_CODE.PLACEHOLDER')"
              @input="$v.shortCode.$touch"
            />
          </label>
        </div>

        <div class="medium-12 columns">
          <label>Select Channel</label>
          <multiselect
            v-model="selectedChannels"
            :options="inboxes"
            track-by="id"
            label="name"
            :limit="2"
            :limit-text="count => '+' + count"
            class="no-margin margin-bottom-2"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="true"
            :internal-search="true"
            :show-labels="true"
            :searchable="true"
            :preserve-search="false"
            :hide-selected="false"
            placeholder="Select channel"
            selected-label
            :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
            :deselect-label="$t('FORMS.MULTISELECT.ENTER_TO_REMOVE')"
          >
            <template slot="tag" slot-scope="props">
              <MultiselectCustomTag
                :option="props.option"
                @remove="props.remove(props.option.id)"
              />
            </template>
          </multiselect>
        </div>

        <div class="medium-12 columns">
          <label :class="{ error: $v.content.$error }">
            {{ $t('CANNED_MGMT.EDIT.FORM.CONTENT.LABEL') }}
          </label>
          <div class="editor-wrap">
            <woot-message-editor
              v-model="content"
              class="message-editor"
              :class="{ editor_warning: $v.content.$error }"
              :enable-variables="true"
              :enable-canned-responses="false"
              :placeholder="$t('CANNED_MGMT.EDIT.FORM.CONTENT.PLACEHOLDER')"
              @blur="$v.content.$touch"
            />
          </div>
        </div>
        <div class="modal-footer">
          <div class="medium-12 columns">
            <woot-submit-button
              :disabled="
                $v.content.$invalid ||
                  $v.shortCode.$invalid ||
                  editCanned.showLoading
              "
              :button-text="$t('CANNED_MGMT.EDIT.FORM.SUBMIT')"
              :loading="editCanned.showLoading"
            />
            <button class="button clear" @click.prevent="onClose">
              {{ $t('CANNED_MGMT.EDIT.CANCEL_BUTTON_TEXT') }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </modal>
</template>

<script>
/* eslint no-console: 0 */
import { required, minLength } from 'vuelidate/lib/validators';
import WootMessageEditor from 'dashboard/components/widgets/WootWriter/Editor';
import WootSubmitButton from '../../../../components/buttons/FormSubmitButton';
import alertMixin from 'shared/mixins/alertMixin';
import Modal from '../../../../components/Modal';
import { removeExtraLines } from 'dashboard/helper/commons.js';
import MultiselectCustomTag from 'dashboard/components/MultiselectCustomTag.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    WootSubmitButton,
    Modal,
    WootMessageEditor,
    MultiselectCustomTag,
  },
  mixins: [alertMixin],
  props: {
    id: { type: Number, default: null },
    edcontent: { type: String, default: '' },
    edshortCode: { type: String, default: '' },
    edinboxes: { type: Function, default: () => [] },
    onClose: { type: Function, default: () => {} },
  },
  data() {
    return {
      editCanned: {
        showAlert: false,
        showLoading: false,
      },
      shortCode: this.edshortCode,
      content: this.edcontent,
      show: true,
      selectedChannels: [],
    };
  },
  validations: {
    shortCode: {
      required,
      minLength: minLength(2),
    },
    content: {
      required,
    },
  },
  computed: {
    ...mapGetters({
      inboxes: 'inboxes/getInboxes',
    }),
    pageTitle() {
      return `${this.$t('CANNED_MGMT.EDIT.TITLE')} - ${this.edshortCode}`;
    },
  },
  created() {
    this.selectedChannels = this.edinboxes;
  },
  methods: {
    setPageName({ name }) {
      this.$v.content.$touch();
      this.content = name;
    },
    resetForm() {
      this.shortCode = '';
      this.content = '';
      this.$v.shortCode.$reset();
      this.$v.content.$reset();
    },
    editCannedResponse() {
      // Show loading on button
      this.editCanned.showLoading = true;
      // Make API Calls
      const inbox_ids = this.selectedChannels.map(item => item.id);

      this.$store
        .dispatch('updateCannedResponse', {
          id: this.id,
          short_code: this.shortCode,
          content: removeExtraLines(this.content, 'start_and_end'),
          inbox_ids: inbox_ids.length ? inbox_ids : null,
        })
        .then(() => {
          // Reset Form, Show success message
          this.editCanned.showLoading = false;
          this.showAlert(
            this.$t('CANNED_MGMT.EDIT.API.SUCCESS_MESSAGE'),
            'success'
          );
          this.resetForm();
          setTimeout(() => {
            this.onClose();
          }, 10);
        })
        .catch(error => {
          this.editCanned.showLoading = false;
          const errorMessage =
            error?.message || this.$t('CANNED_MGMT.EDIT.API.ERROR_MESSAGE');
          this.showAlert(errorMessage, 'error');
        });
    },
  },
};
</script>
<style scoped lang="scss">
.no-margin {
  margin-bottom: var(--space-small);
  &::v-deep .multiselect__strong {
    margin-right: var(--space-smaller) !important;
    margin-bottom: 0 !important;
    line-height: 0 !important;
    font-weight: 400 !important;
    font-size: var(--font-size-small) !important;
  }
  &::v-deep.multiselect .multiselect__tags {
    display: flex !important;
    align-items: center !important;
    border-radius: 12px !important;
    .multiselect__tags-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 0;
    }
  }
  &::v-deep.multiselect__tags {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }
  &::v-deep .multiselect__tag {
    max-width: 150px !important;
    @media screen and (max-width: 1250px) {
      max-width: 80px !important;
    }
  }
}
::v-deep {
  .ProseMirror-menubar {
    display: none;
  }

  .ProseMirror-woot-style {
    min-height: 20rem;

    p {
      font-size: var(--font-size-default);
    }
  }

  .message-editor {
    border: 1px solid var(--s-200);
  }
}
</style>
