import { QUEUE_MUTATION_TYPES } from './queueMutations';

const DebouncedCommitPlugin = store => {
  const commitQueue = [];
  let timer = null;

  // Override commit method
  store.commit = (function overrideCommit(originalCommit) {
    return function debouncedCommit(mutationType, mutationPayload) {
      // Ignore debouncing by default
      if (!window.enableDebouncedCommit) {
        originalCommit.call(store, mutationType, mutationPayload);
        return;
      }

      // Skip debouncing for critical mutations
      const skippedDebounceCommites = Array.isArray(
        window.debouncedQueueMutationTypes
      )
        ? window.debouncedQueueMutationTypes.concat(QUEUE_MUTATION_TYPES)
        : QUEUE_MUTATION_TYPES;

      if (!skippedDebounceCommites.includes(mutationType.split('/')[0])) {
        console.log('[debounced-plugin] [immediate]', mutationType); // eslint-disable-line no-console
        originalCommit.call(store, mutationType, mutationPayload);
        return;
      }

      // Add the mutation to the queue
      commitQueue.push({ type: mutationType, payload: mutationPayload });

      // Set a timer to batch process commits
      if (!timer) {
        timer = setTimeout(() => {
          commitQueue.forEach(
            ({ type: queuedType, payload: queuedPayload }) => {
              console.log('[debounced-plugin] [queued]', queuedType); // eslint-disable-line no-console
              originalCommit.call(store, queuedType, queuedPayload);
            }
          );

          // Clear the queue and reset the timer
          commitQueue.length = 0;
          timer = null;
        }, window.debouncedCommitDelay || 1000);
      }
    };
  })(store.commit);
};

export default DebouncedCommitPlugin;
