<template>
  <div>
    <ve-table
      :fixed-header="true"
      max-height="calc(100vh - 20.6rem)"
      scroll-width="60rem"
      :columns="columns"
      :table-data="items"
      :border-around="false"
    />
  </div>
</template>

<script>
import { VeTable } from 'vue-easytable';
import messageFormatterMixin from 'shared/mixins/messageFormatterMixin';
import dompurifyMixin from 'shared/mixins/dompurifyMixin';

export default {
  components: {
    VeTable,
  },
  mixins: [messageFormatterMixin, dompurifyMixin],
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    searchText: {
      type: String,
      default: '',
    },
    loading: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    columns() {
      return [
        {
          field: 'title',
          key: 'title',
          title: this.$t('LABEL_MGMT.LIST.TABLE_HEADER')[0],
          align: 'left',
          renderBodyCell: ({ row }) =>
            this.searchText ? this.renderHighlightedText(row.title) : row.title,
        },
        {
          field: 'description',
          key: 'description',
          title: this.$t('LABEL_MGMT.LIST.TABLE_HEADER')[1],
          align: 'left',
          renderBodyCell: ({ row }) =>
            this.searchText
              ? this.renderHighlightedText(row.description)
              : row.description,
        },
        {
          field: 'color',
          key: 'color',
          title: this.$t('LABEL_MGMT.LIST.TABLE_HEADER')[2],
          align: 'left',
          renderBodyCell: ({ row }) => {
            return (
              <div class="label-color--container">
                <span
                  class="label-color--display"
                  style={{ backgroundColor: row.color }}
                />
                <div class="message-content">{row.color}</div>
              </div>
            );
          },
        },
        {
          field: 'actions',
          key: 'actions',
          title: 'Actions',
          align: 'center',
          width: 100,
          renderBodyCell: ({ row, index }) => (
            <div class="button-wrapper">
              <woot-button
                variant="smooth"
                size="tiny"
                color-scheme="secondary"
                class-names="grey-btn"
                is-loading={this.loading[row.id]}
                icon="edit"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                fill-color="none"
                onClick={() => this.$emit('openEditPopup', row)}
              />
              <woot-button
                variant="smooth"
                color-scheme="alert"
                size="tiny"
                icon="dismiss-circle"
                class-names="grey-btn"
                is-loading={this.loading[row.id]}
                onClick={() => this.$emit('openDeletePopup', row, index)}
              />
            </div>
          ),
        },
      ];
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.canned-actions-btn {
  margin-left: 14px;
}

.message-content::v-deep p,
.message-content::v-deep li::marker {
  color: var(--h-700);
  margin-bottom: var(--space-smaller);
}

.message-content {
  overflow-wrap: break-word;
}

.message-content::v-deep .searchkey--highlight {
  color: var(--v-600);
  font-weight: var(--font-weight-black);
  font-size: var(--font-size-small);
}

.label-color--container {
  display: flex;
  align-items: center;
}

.label-color--display {
  border-radius: $space-smaller;
  height: $space-normal;
  margin-right: $space-smaller;
  width: $space-normal;
  border: 1px solid var(--color-border-light);
}
.button-wrapper {
  justify-content: space-evenly;
  display: flex;
  flex-direction: row;
}
</style>
