<template>
  <div class="no-result">
    <img
      src="../../assets/images/no-result-found.svg"
      alt="No result"
      loading="lazy"
    />
    <h2>{{ title }}</h2>
    <p>{{ description }}</p>
  </div>
</template>

<script>
export default {
  name: 'NoResultCard',
  props: {
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped>
.no-result {
  text-align: center;
  padding: 20px;
  margin-top: 25%;
}

.no-result h2 {
  font-size: 24px;
  margin-top: 20px;
}

.no-result p {
  color: #666;
  font-size: 16px;
  margin-top: 10px;
}
</style>
