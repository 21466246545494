<template>
  <div>
    <woot-delete-modal
      v-if="sessionNotify"
      :show.sync="sessionNotify"
      :message="$t('LOGIN.LOGIN_SESSION.MESSAGE')"
      :confirm-text="$t('LOGIN.LOGIN_SESSION.BTN')"
      reject-text="OK"
      :on-close="() => closeNotify(false)"
      :on-confirm="() => closeNotify(true)"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  clearCookiesOnLogout,
  deleteIndexedDBOnLogout,
} from 'dashboard/store/utils/api';

export default {
  computed: {
    ...mapGetters({
      sessionNotify: 'userNotificationSettings/getSessionNotify',
    }),
  },

  methods: {
    closeNotify(reset) {
      clearCookiesOnLogout();
      deleteIndexedDBOnLogout();
      this.$store.dispatch('userNotificationSettings/setSessionNotify', false);
      if (reset) window.location = '/app/auth/reset/password';
    },
  },
};
</script>
