/* global axios */

import ApiClient from './ApiClient';

class CannedResponse extends ApiClient {
  constructor() {
    super('canned_responses', { accountScoped: true });
  }

  get(page, search, inbox_id) {
    return axios.get(this.url, {
      params: {
        page,
        ...(search ? { search } : {}),
        ...(inbox_id ? { inbox_id } : {}),
      },
    });
  }
}

export default new CannedResponse();
