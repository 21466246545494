const RANGE = {
  RTL: '\u0591-\u07FF\uFB1D-\uFDFD\uFE70-\uFEFC',
  LTR:
    'A-Za-z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u02B8\u0300-\u0590\u0800-\u1FFF\u200E\u2C00-\uFB1C\uFE00-\uFE6F\uFEFD-\uFFFF',
};

/* eslint-disable no-misleading-character-class */
const rtl = new RegExp(`^[^${RANGE.LTR}]*[${RANGE.RTL}]`);
const ltr = new RegExp(`^[^${RANGE.RTL}]*[${RANGE.LTR}]`);

/* eslint-enable no-misleading-character-class */
/**
 * Detect the direction of text: left-to-right, right-to-left, or neutral
 *
 * @param {string} value
 * @returns {'rtl'|'ltr'|false}
 */
export const getLanguageDirection = value => {
  const source = String(value || '');

  if (rtl.test(source)) {
    return 'rtl';
  }

  if (ltr.test(source)) {
    return 'ltr';
  }

  return false;
};
