<template>
  <modal :show.sync="show" :on-close="onClose">
    <div class="column">
      <woot-modal-header
        :header-title="$t('CANNED_MGMT.ADD.TITLE')"
        :header-content="$t('CANNED_MGMT.ADD.DESC')"
      />
      <form class="row" @submit.prevent="addCannedResponse()">
        <div class="medium-12 columns">
          <label :class="{ error: $v.shortCode.$error }">
            {{ $t('CANNED_MGMT.ADD.FORM.SHORT_CODE.LABEL') }}
            <input
              v-model.trim="shortCode"
              type="text"
              :placeholder="$t('CANNED_MGMT.ADD.FORM.SHORT_CODE.PLACEHOLDER')"
              data-testid="shortCode-input"
              @input="$v.shortCode.$touch"
            />
          </label>
        </div>

        <div class="medium-12 columns">
          <label>Select Channel</label>
          <multiselect
            v-model="selectedChannels"
            :options="inboxes"
            track-by="id"
            label="name"
            :limit="2"
            :limit-text="count => '+' + count"
            class="no-margin margin-bottom-2"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="true"
            :internal-search="true"
            :show-labels="true"
            :searchable="true"
            :preserve-search="false"
            :hide-selected="false"
            placeholder="Select channel"
            selected-label
            :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
            :deselect-label="$t('FORMS.MULTISELECT.ENTER_TO_REMOVE')"
          >
            <template slot="tag" slot-scope="props">
              <MultiselectCustomTag
                :option="props.option"
                @remove="props.remove(props.option.id)"
              />
            </template>
          </multiselect>
        </div>

        <div class="medium-12 columns">
          <label :class="{ error: $v.content.$error }">
            {{ $t('CANNED_MGMT.ADD.FORM.CONTENT.LABEL') }}
          </label>
          <div class="editor-wrap">
            <woot-message-editor
              v-model="content"
              class="message-editor"
              :class="{ editor_warning: $v.content.$error }"
              :enable-variables="true"
              :enable-canned-responses="false"
              :placeholder="$t('CANNED_MGMT.ADD.FORM.CONTENT.PLACEHOLDER')"
              @blur="$v.content.$touch"
            />
          </div>
        </div>
        <div class="modal-footer">
          <div class="medium-12 columns">
            <woot-submit-button
              :disabled="
                $v.content.$invalid ||
                  $v.shortCode.$invalid ||
                  addCanned.showLoading
              "
              :button-text="$t('CANNED_MGMT.ADD.FORM.SUBMIT')"
              :loading="addCanned.showLoading"
            />
            <button class="button clear" @click.prevent="onClose">
              {{ $t('CANNED_MGMT.ADD.CANCEL_BUTTON_TEXT') }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </modal>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators';
import metaMixin from 'shared/mixins/metaMixin';
import MultiselectCustomTag from 'dashboard/components/MultiselectCustomTag.vue';
import WootSubmitButton from '../../../../components/buttons/FormSubmitButton';
import Modal from '../../../../components/Modal';
import WootMessageEditor from 'dashboard/components/widgets/WootWriter/Editor';
import alertMixin from 'shared/mixins/alertMixin';
import { removeExtraLines } from 'dashboard/helper/commons.js';
import { mapGetters } from 'vuex';

export default {
  components: {
    WootSubmitButton,
    Modal,
    WootMessageEditor,
    MultiselectCustomTag,
  },
  mixins: [alertMixin, metaMixin],
  props: {
    responseContent: {
      type: String,
      default: '',
    },
    onClose: {
      type: Function,
      default: () => {},
    },
    pageSize: {
      type: Number,
      default: 20,
    },
  },
  data() {
    return {
      shortCode: '',
      content: this.responseContent || '',
      addCanned: {
        showLoading: false,
        message: '',
      },
      show: true,
      selectedChannels: [],
    };
  },
  computed: {
    ...mapGetters({
      inboxes: 'inboxes/getInboxes',
    }),
  },
  validations: {
    shortCode: {
      required,
      minLength: minLength(2),
    },
    content: {
      required,
    },
  },
  methods: {
    resetForm() {
      this.shortCode = '';
      this.content = '';
      this.$v.shortCode.$reset();
      this.$v.content.$reset();
    },
    addCannedResponse() {
      // Show loading on button
      this.addCanned.showLoading = true;
      // Make API Calls
      const inbox_ids = this.selectedChannels.map(item => item.id);
      this.$store
        .dispatch('createCannedResponse', {
          short_code: this.shortCode,
          content: removeExtraLines(this.content, 'start_and_end'),
          inbox_ids: inbox_ids.length ? inbox_ids : null,
        })
        .then(resData => {
          // Reset Form, Show success message
          this.addCanned.showLoading = false;
          this.showAlert(
            this.$t('CANNED_MGMT.ADD.API.SUCCESS_MESSAGE'),
            'success'
          );
          this.resetForm();
          this.onClose();
          this.execUpdateRecordsAndMetadata({
            action: 'create',
            perPageCount: this.pageSize,
            record: resData,
            moduleName: 'cannedResponse',
          });
        })
        .catch(error => {
          this.addCanned.showLoading = false;
          const errorMessage =
            error?.message || this.$t('CANNED_MGMT.ADD.API.ERROR_MESSAGE');
          this.showAlert(errorMessage, 'error');
        });
    },
  },
};
</script>

<style scoped lang="scss">
.no-margin {
  margin-bottom: var(--space-small);
  &::v-deep .multiselect__strong {
    margin-right: var(--space-smaller) !important;
    margin-bottom: 0 !important;
    line-height: 0 !important;
    font-weight: 400 !important;
    font-size: var(--font-size-small) !important;
  }
  &::v-deep.multiselect .multiselect__tags {
    display: flex !important;
    align-items: center !important;
    border-radius: 12px !important;
    .multiselect__tags-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 0;
    }
  }
  &::v-deep.multiselect__tags {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }
  &::v-deep .multiselect__tag {
    max-width: 150px !important;
    @media screen and (max-width: 1250px) {
      max-width: 80px !important;
    }
  }
}
::v-deep {
  .ProseMirror-menubar {
    display: none;
  }

  .ProseMirror-woot-style {
    min-height: 20rem;

    p {
      font-size: var(--font-size-default);
    }
  }

  .message-editor {
    border: 1px solid var(--s-200);
  }
}
</style>
