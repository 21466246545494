import axios from 'axios';
import wootConstants from 'dashboard/constants/globals.js';
import ApiClient from './ApiClient';
import auth from './auth';

class SwitchesAIsAPI extends ApiClient {
  SERVICES_PATH = wootConstants.SERVICES_PATH;

  setKbToRbSwitches(payload, account_id) {
    const authToken = auth.getAuthToken();
    return axios.post(
      `/${this.SERVICES_PATH.BOT}/v1/kb_to_rb_switches/accounts/${account_id}/switches_with_conditions_and_arguments`,
      payload,
      {
        headers: {
          accept: 'application/json',
          Authorization: authToken,
          'Content-Type': 'application/json',
        },
      }
    );
  }

  getSwitchesAIs(account_id, type_bot_id) {
    const authToken = auth.getAuthToken();
    return axios.get(
      `/${this.SERVICES_PATH.BOT}/v1/kb_to_rb_switches/accounts/${account_id}/switches`,
      {
        params: {
          from_agentbot_id: type_bot_id,
        },
        headers: {
          accept: 'application/json',
          Authorization: authToken,
          'Content-Type': 'application/json',
        },
      }
    );
  }

  deleteSwitchesAI(switch_id, account_id) {
    const authToken = auth.getAuthToken();
    return axios.delete(
      `/${this.SERVICES_PATH.BOT}/v1/kb_to_rb_switches/accounts/${account_id}/switches/${switch_id}`,
      {
        headers: {
          accept: 'application/json',
          Authorization: authToken,
          'Content-Type': 'application/json',
        },
      }
    );
  }

  updateSwitcher(payload, account_id) {
    const authToken = auth.getAuthToken();
    const id = payload.id;
    delete payload.id;
    return axios.patch(
      `/${this.SERVICES_PATH.BOT}/v1/kb_to_rb_switches/accounts/${account_id}/switches/${id}`,
      payload,
      {
        headers: {
          accept: 'application/json',
          Authorization: authToken,
          'Content-Type': 'application/json',
        },
      }
    );
  }

  updateCondition(condition, account_id, condition_id) {
    const authToken = auth.getAuthToken();

    return axios.patch(
      `/${this.SERVICES_PATH.BOT}/v1/kb_to_rb_switches/accounts/${account_id}/conditions/${condition_id}`,
      { condition },
      {
        headers: {
          accept: 'application/json',
          Authorization: authToken,
          'Content-Type': 'application/json',
        },
      }
    );
  }
}

export default new SwitchesAIsAPI();
