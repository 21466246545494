/* global axios */
import ApiClient from './ApiClient';

class Agents extends ApiClient {
  constructor() {
    super('agents', { accountScoped: true });
  }

  get(page, search) {
    return axios.get(this.url, {
      params: {
        page,
        ...(search ? { search } : {}),
      },
    });
  }
}

export default new Agents();
