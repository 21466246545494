<template>
  <div class="woot-table">
    <ve-table
      :fixed-header="true"
      max-height="calc(100vh - 20.6rem)"
      scroll-width="60rem"
      :columns="columns"
      :table-data="items"
      :border-around="false"
    />
  </div>
</template>

<script>
import { VeTable } from 'vue-easytable';
import { mapGetters } from 'vuex';
import accountMixin from 'dashboard/mixins/account';
import adminMixin from '../../../../mixins/isAdmin';
import flag from '../../../../assets/images/flag.svg';

export default {
  components: {
    VeTable,
  },
  mixins: [accountMixin, adminMixin],
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    searchText: {
      type: String,
      default: '',
    },
    loading: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    checkAdmin() {
      return this.isAdmin;
    },
    ...mapGetters({
      globalConfig: 'globalConfig/get',
    }),
    columns() {
      const getChannelLabel = (channelType, item) => {
        switch (channelType) {
          case 'Channel::FacebookPage':
            return 'Facebook';
          case 'Channel::WebWidget':
            return 'Website';
          case 'Channel::TwitterProfile':
            return 'Twitter';
          case 'Channel::TwilioSms':
            return this.twilioChannelName(item);
          case 'Channel::Whatsapp':
            return 'Whatsapp';
          case 'Channel::Sms':
            return 'Sms';
          case 'Channel::Call':
            return 'Call';
          case 'Channel::Email':
            return 'Email';
          case 'Channel::Telegram':
            return 'Telegram';
          case 'Channel::Line':
            return 'Line';
          case 'Channel::Api':
            return this.globalConfig.apiChannelName || 'API';
          default:
            return '';
        }
      };
      return [
        {
          field: 'icon',
          key: 'icon',
          title: 'Avatar',
          align: 'start',
          width: 90,
          renderBodyCell: ({ row }) => {
            return (
              <div class="logo-wrapper">
                {row.avatar_url ? (
                  <img
                    width="50"
                    height="50"
                    class="woot-thumbnail"
                    src={row.avatar_url}
                    alt="No Page Image"
                  />
                ) : (
                  <img
                    width="50"
                    height="50"
                    class="woot-thumbnail"
                    src={flag}
                    alt="No Page Image"
                  />
                )}
              </div>
            );
          },
        },
        {
          field: 'type',
          key: 'type',
          title: 'Channel Type',
          align: 'start',
          renderBodyCell: ({ row }) => {
            const channelLabel = getChannelLabel(row.channel_type, row);
            return (
              <div>
                <span class="agent-name">{row.name}</span>
                <span>{channelLabel}</span>
              </div>
            );
          },
        },
        {
          field: 'actions',
          key: 'actions',
          title: 'Actions',
          align: 'right',
          renderBodyCell: ({ row }) => {
            const checkAdmin = this.checkAdmin;
            return (
              <div class="button-wrapper">
                {checkAdmin && (
                  <woot-button
                    v-tooltip={this.$t('INBOX_MGMT.SETTINGS')}
                    variant="smooth"
                    size="tiny"
                    icon="settings"
                    color-scheme="secondary"
                    class-names="grey-btn"
                    onClick={() => this.$emit('redirectToSettings', row)}
                  />
                )}
                {checkAdmin && (
                  <woot-button
                    v-tooltip={this.$t('INBOX_MGMT.DELETE.BUTTON_TEXT')}
                    variant="smooth"
                    color-scheme="alert"
                    size="tiny"
                    class="grey-btn"
                    icon="dismiss-circle"
                    onClick={() => this.$emit('deleteChannel', row)}
                  />
                )}
              </div>
            );
          },
        },
      ];
    },
  },
  methods: {
    twilioChannelName(item) {
      const { medium = '' } = item;
      if (medium === 'whatsapp') return 'WhatsApp';
      return 'Twilio SMS';
    },
  },
};
</script>
<style lang="scss">
@import '../../../../../dashboard/assets/scss/widgets/_woot-tables.scss';
</style>
