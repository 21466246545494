import DOMPurify from 'dompurify';

export default {
  methods: {
    renderHighlightedText(text) {
      if (!this.searchText) {
        return this.$createElement(
          'div',
          { class: 'message-content' },
          this.sanitizeHTML(text)
        );
      }

      const escapedSearchTerm = this.escapeRegExp(this.searchText);
      const parts = text.split(new RegExp(`(${escapedSearchTerm})`, 'ig'));

      return this.$createElement(
        'div',
        { class: 'message-content' },
        parts.map(part =>
          part.toLowerCase() === this.searchText.toLowerCase()
            ? this.$createElement(
                'span',
                { class: 'searchkey--highlight' },
                part
              )
            : this.sanitizeHTML(part)
        )
      );
    },

    sanitizeHTML(html) {
      return DOMPurify.sanitize(html, {
        ALLOWED_TAGS: ['span'],
        ALLOWED_ATTR: ['class'],
        FORBID_TAGS: ['iframe', 'script', 'svg', 'math'],
        FORBID_ATTR: ['onerror', 'onclick', 'href', 'style', 'src'],
        FORBID_CONTENTS: true,
      });
    },

    escapeRegExp(string) {
      return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    },
  },
};
