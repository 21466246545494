<template>
  <div class="column content-box">
    <woot-button
      color-scheme="success"
      class-names="button--fixed-top"
      icon="add-circle"
      @click="openAddPopup"
    >
      {{ $t('LABEL_MGMT.HEADER_BTN_TXT') }}
    </woot-button>
    <div class="row table-view">
      <div class="small-8 columns with-right-space">
        <TableHeader
          :has-search="true"
          :initial-search-value="search"
          @applySearch="execApplySearch"
        />
        <no-result-card
          v-if="!records.length && !isSearching"
          :title="$t('CANNED_MGMT.NOT_FOUND.TITLE')"
        />
        <woot-loading-state
          v-if="isSearching"
          :message="$t('LABEL_MGMT.LOADING')"
        />
        <labels-table
          v-if="!uiFlags.isFetching && records.length && !isSearching"
          :items="records"
          :search-text="search"
          :loading="loading"
          @openEditPopup="openEditPopup"
          @openDeletePopup="openDeletePopup"
        />
        <table-footer
          v-if="!uiFlags.isFetching && records.length && !isSearching"
          :current-page="Number(meta.currentPage)"
          :total-count="meta.count"
          :page-size="perPageCount"
          :records-count="records.length"
          @page-change="fetchLabels"
        />
      </div>

      <div class="small-4 columns">
        <span v-dompurify-html="$t('LABEL_MGMT.SIDEBAR_TXT')" />
      </div>
    </div>
    <woot-modal :show.sync="showAddPopup" :on-close="hideAddPopup">
      <add-label :page-size="perPageCount" @close="hideAddPopup" />
    </woot-modal>

    <woot-modal :show.sync="showEditPopup" :on-close="hideEditPopup">
      <edit-label
        :selected-response="selectedResponse"
        @close="hideEditPopup"
      />
    </woot-modal>

    <woot-delete-modal
      :show.sync="showDeleteConfirmationPopup"
      :on-close="closeDeletePopup"
      :on-confirm="confirmDeletion"
      :title="$t('LABEL_MGMT.DELETE.CONFIRM.TITLE')"
      :message="$t('LABEL_MGMT.DELETE.CONFIRM.MESSAGE')"
      :message-value="deleteMessage"
      :confirm-text="deleteConfirmText"
      :reject-text="deleteRejectText"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

import AddLabel from './AddLabel';
import EditLabel from './EditLabel';
import alertMixin from 'shared/mixins/alertMixin';
import LabelsTable from './LabelsTable.vue';
import NoResultCard from 'dashboard/components/ui/NoResultCard.vue';
import TableFooter from 'dashboard/components/widgets/TableFooter';
import urlMixin from '../../../../mixins/urlMixin';
import metaMixin from 'shared/mixins/metaMixin';
import TableHeader from 'dashboard/components/widgets/TableHeader.vue';

export default {
  components: {
    NoResultCard,
    LabelsTable,
    AddLabel,
    EditLabel,
    TableFooter,
    TableHeader,
  },
  mixins: [alertMixin, urlMixin, metaMixin],
  data() {
    return {
      loading: {},
      showAddPopup: false,
      showEditPopup: false,
      showDeleteConfirmationPopup: false,
      selectedResponse: {},
      search: this.$route.query.search || '',
      isSearching: false,
      perPageCount: 20,
    };
  },
  computed: {
    ...mapGetters({
      records: 'labels/getLabels',
      uiFlags: 'labels/getUIFlags',
      meta: 'labels/getMeta',
    }),
    // Delete Modal
    deleteConfirmText() {
      return `${this.$t('LABEL_MGMT.DELETE.CONFIRM.YES')} ${
        this.selectedResponse.title
      }`;
    },
    deleteRejectText() {
      return `${this.$t('LABEL_MGMT.DELETE.CONFIRM.NO')} ${
        this.selectedResponse.title
      }`;
    },
    deleteMessage() {
      return ` ${this.selectedResponse.title}?`;
    },
  },
  mounted() {
    this.fetchLabels(this.meta.currentPage);
  },
  destroyed() {
    this.fetchLabels();
  },
  methods: {
    execApplySearch(data) {
      this.search = data.search;
      this.isSearching = true;
      this.fetchLabels(1);
    },
    openAddPopup() {
      this.showAddPopup = true;
    },
    hideAddPopup() {
      this.showAddPopup = false;
    },

    openEditPopup(response) {
      this.showEditPopup = true;
      this.selectedResponse = response;
    },
    hideEditPopup() {
      this.showEditPopup = false;
    },
    fetchLabels(page) {
      if (page) {
        this.updatePageParams(page, this.search);
        this.$store.dispatch('labels/get', {
          page,
          search: this.search,
        });
      } else {
        this.$store.dispatch('labels/get');
      }
      this.isSearching = false;
    },
    openDeletePopup(response) {
      this.showDeleteConfirmationPopup = true;
      this.selectedResponse = response;
    },
    closeDeletePopup() {
      this.showDeleteConfirmationPopup = false;
    },

    confirmDeletion() {
      this.loading[this.selectedResponse.id] = true;
      this.closeDeletePopup();
      this.deleteLabel(this.selectedResponse.id);
    },
    deleteLabel(id) {
      this.$store
        .dispatch('labels/delete', id)
        .then(() => {
          this.showAlert(
            this.$t('LABEL_MGMT.DELETE.API.SUCCESS_MESSAGE'),
            'success'
          );
          this.execUpdateRecordsAndMetadata(
            {
              action: 'delete',
              perPageCount: this.perPageCount,
              record: id,
              moduleName: 'labels',
            },
            () => {
              this.fetchLabels(this.meta.currentPage);
            }
          );
        })
        .catch(() => {
          this.showAlert(
            this.$t('LABEL_MGMT.DELETE.API.ERROR_MESSAGE'),
            'error'
          );
        })
        .finally(() => {
          this.loading[this.selectedResponse.id] = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/variables';

.label-color--container {
  display: flex;
  align-items: center;
}

.label-color--display {
  border-radius: $space-smaller;
  height: $space-normal;
  margin-right: $space-smaller;
  width: $space-normal;
  border: 1px solid var(--color-border-light);
}
.label-title {
  span {
    width: var(--space-giga);
    display: inline-block;
  }
}
</style>
