<template>
  <div class="column content-box">
    <div class="row table-view">
      <div class="small-8 columns with-right-space ">
        <table-header :has-search="true" @applySearch="execApplySearch" />
        <no-result-card
          v-if="currentState === 'noResult'"
          :title="$t('CANNED_MGMT.NOT_FOUND.TITLE')"
        />
        <spinner-hoory v-if="currentState === 'loading'" class="margin-top-2" />
        <template v-if="currentState === 'records'">
          <TeamTable
            :items="records"
            @deleteTeam="openDelete"
            @redirectToSettings="redirectToTeamSettings"
          />
          <table-footer
            :current-page="Number(meta.currentPage)"
            :total-count="meta.count"
            :page-size="perPageCount"
            :records-count="records.length"
            @page-change="onPageChange"
          />
        </template>
      </div>

      <div class="small-4 columns">
        <span
          v-dompurify-html="
            $t('TEAMS_SETTINGS.SIDEBAR_TXT', {
              installationName: globalConfig.installationName,
            })
          "
        />
      </div>
    </div>
    <woot-confirm-delete-modal
      v-if="showDeletePopup"
      :show.sync="showDeletePopup"
      :title="confirmDeleteTitle"
      :message="$t('TEAMS_SETTINGS.DELETE.CONFIRM.MESSAGE')"
      :confirm-text="deleteConfirmText"
      :reject-text="deleteRejectText"
      :confirm-value="selectedTeam.name"
      :confirm-place-holder-text="confirmPlaceHolderText"
      @on-confirm="confirmDeletion"
      @on-close="closeDelete"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import adminMixin from '../../../../mixins/isAdmin';
import accountMixin from '../../../../mixins/account';
import alertMixin from 'shared/mixins/alertMixin';
import SpinnerHoory from '../../../../../shared/components/SpinnerHoory.vue';
import TeamTable from './TeamTable.vue';
import TableFooter from '../../../../components/widgets/TableFooter.vue';
import metaMixin from 'shared/mixins/metaMixin';
import TableHeader from 'dashboard/components/widgets/TableHeader.vue';
import NoResultCard from 'dashboard/components/ui/NoResultCard.vue';

export default {
  components: {
    TableFooter,
    TeamTable,
    SpinnerHoory,
    TableHeader,
    NoResultCard,
  },
  mixins: [adminMixin, accountMixin, alertMixin, metaMixin],
  data() {
    return {
      isSearching: false,
      showSettings: false,
      showDeletePopup: false,
      selectedTeam: {},
      perPageCount: 20,
      search: '',
    };
  },
  computed: {
    ...mapGetters({
      records: 'teams/getTeams',
      meta: 'teams/getMeta',
      globalConfig: 'globalConfig/get',
      uiFlags: 'teams/getUIFlags',
    }),
    deleteConfirmText() {
      return `${this.$t('TEAMS_SETTINGS.DELETE.CONFIRM.YES')} ${
        this.selectedTeam.name
      }`;
    },
    deleteRejectText() {
      return this.$t('TEAMS_SETTINGS.DELETE.CONFIRM.NO');
    },
    confirmDeleteTitle() {
      return this.$t('TEAMS_SETTINGS.DELETE.CONFIRM.TITLE', {
        teamName: this.selectedTeam.name,
      });
    },
    confirmPlaceHolderText() {
      return `${this.$t('TEAMS_SETTINGS.DELETE.CONFIRM.PLACE_HOLDER', {
        teamName: this.selectedTeam.name,
      })}`;
    },
  },
  mounted() {
    this.fetchTeams(this.meta.currentPage);
  },
  methods: {
    execApplySearch(data) {
      this.search = data.search;
      this.fetchTeams(1);
    },

    async fetchTeams(page) {
      this.isSearching = true;
      await this.$store.dispatch('teams/get', { page, search: this.search });
      this.isSearching = false;
    },
    onPageChange(page) {
      this.fetchTeams(page);
    },
    async deleteTeam({ id }) {
      try {
        await this.$store.dispatch('teams/delete', id);
        this.showAlert(
          this.$t('TEAMS_SETTINGS.DELETE.API.SUCCESS_MESSAGE'),
          'success'
        );
        this.execUpdateRecordsAndMetadata(
          {
            action: 'delete',
            perPageCount: this.perPageCount,
            record: id,
            moduleName: 'teams',
          },
          () => {
            this.fetchTeams(this.meta.currentPage);
          }
        );
      } catch (error) {
        this.showAlert(
          this.$t('TEAMS_SETTINGS.DELETE.API.ERROR_MESSAGE'),
          'error'
        );
      }
    },
    redirectToTeamSettings(row) {
      this.$router.push(
        `${this.addAccountScoping(`settings/teams/${row.id}/edit`)}`
      );
    },
    confirmDeletion() {
      this.deleteTeam(this.selectedTeam);
      this.closeDelete();
    },
    openDelete(team) {
      this.showDeletePopup = true;
      this.selectedTeam = team;
    },
    closeDelete() {
      this.showDeletePopup = false;
      this.selectedTeam = {};
    },
  },
};
</script>
<style lang="scss" scoped>
.button-wrapper {
  min-width: unset;
  justify-content: flex-end;
}
</style>
