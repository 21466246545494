<template>
  <div ref="selectMenu" class="select-menu" :class="{ active: isActive }">
    <div class="select-btn" @click="toggleDropdown">
      <span class="sBtn-text">{{ selectedText }}</span>
      <fluent-icon :icon="isActive ? 'chevron-up' : 'chevron-down'" />
    </div>

    <ul v-if="isActive" class="options">
      <li v-if="filteredOptions.length > 0">
        <div class="filter-buttons">
          <div
            v-for="(button, index) in buttons"
            :key="index"
            class="filter-button"
            :class="{
              active: defaultType(button.type),
              disabled: !hasOptions(button.type),
            }"
            :disabled="!hasOptions(button.type)"
            @click="applyFilter(button.type)"
          >
            {{ button.label }}
          </div>
        </div>
      </li>
      <li
        v-for="(option, index) in filteredOptions"
        :key="index"
        class="option"
        :class="{ activeOption: selectedId === option.id }"
        @click="selectOption(option)"
      >
        <span class="option-text">{{ option.label }}</span>
      </li>
      <li
        v-if="additionalActionBottom && filteredOptions.length"
        class="option"
        @click="$emit('action')"
      >
        <span class="option-text assistants-list">{{
          additionalActionBottom
        }}</span>
      </li>
      <li v-if="!filteredOptions.length">
        <slot />
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      required: true,
      default: () => [],
    },
    buttons: {
      type: Array,
      required: true,
      default: () => [{ label: '', type: '' }],
    },
    buttonType: {
      type: String,
      required: true,
    },
    selectedItem: {
      type: Object,
      required: true,
      default: () => {},
    },
    additionalActionBottom: {
      type: String,
      required: false,
      default: '',
    },
    placeholder: {
      type: String,
      default: 'Select your option',
    },
  },
  data() {
    return {
      isActive: false,
      type: '',
      selectedText: this.placeholder,
    };
  },
  computed: {
    filteredOptions() {
      return this.options.filter(option => this.defaultType(option.type));
    },
    selectedId() {
      return this.selectedItem?.id;
    },
  },
  watch: {
    placeholder(newValue) {
      this.selectedText = newValue;
    },
    buttonType(newValue) {
      this.type = newValue;
    },
  },
  mounted() {
    document.addEventListener('click', this.handleOutsideClick);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleOutsideClick);
  },
  methods: {
    defaultType(type) {
      if (this.buttonType) {
        return this.buttonType === type;
      }
      return this.type === type;
    },
    toggleDropdown() {
      this.isActive = !this.isActive;
    },
    selectOption(option) {
      this.selectedText = option.label;
      this.isActive = false;
      this.$emit('select', option);
    },
    applyFilter(type) {
      if (this.hasOptions(type)) {
        this.type = type;
        this.$emit('changeButtonType', type);
      }
    },
    hasOptions(type) {
      return this.options.some(option => option.type === type);
    },
    handleOutsideClick(event) {
      if (
        this.$refs.selectMenu &&
        !this.$refs.selectMenu.contains(event.target)
      ) {
        this.isActive = false;
      }
    },
  },
};
</script>
<style scoped lang="scss">
ul {
  list-style-type: none !important;
}
.select-menu {
  max-width: 330px;
  margin: 0 0 1.6rem;
  position: relative;
}
.select-menu .select-btn {
  display: flex;
  height: 48px;
  background: var(--v-50);
  color: var(--s-600);
  padding: 12px;
  font-size: 15px;
  font-weight: 400;
  border-radius: 12px;
  border: 1px solid #f0f0f5;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
}
.select-menu .options {
  position: absolute;
  width: 100%;
  overflow-y: auto;
  max-height: 400px;
  padding: 10px;
  margin-top: 10px;
  border-radius: 8px;
  margin-left: 0 !important;
  background: var(--v-50);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  animation-name: fadeInDown;
  -webkit-animation-name: fadeInDown;
  animation-duration: 0.35s;
  animation-fill-mode: both;
  -webkit-animation-duration: 0.35s;
  -webkit-animation-fill-mode: both;
}
.select-menu .options .option {
  cursor: pointer;
  padding: 12px 10px;
  border-radius: var(--border-radius-normal);
  text-align: start;
}
.select-menu .options .option:hover {
  background: var(--V-50);
  color: var(--v-400);
}

.filter-buttons .filter-button.disabled {
  color: var(--s-600);
  cursor: not-allowed;
  opacity: 0.5;
}

.select-menu .options .option .option-text {
  font-size: var(--font-size-default);
  font-weight: var(--font-weight-normal);
}

.select-menu.active .options {
  display: block;
  opacity: 0;
  z-index: 10;
  animation-name: fadeInUp;
  -webkit-animation-name: fadeInUp;
  animation-duration: 0.4s;
  animation-fill-mode: both;
  -webkit-animation-duration: 0.4s;
  -webkit-animation-fill-mode: both;
}

.filter-buttons {
  display: flex;
  margin-bottom: 12px;
  padding: 3px 7px 5px;
  border-radius: var(--border-radius-normal);
  background: var(--color-background);
  text-align: center;
}

.filter-buttons .filter-button {
  width: 100%;
  padding: 10px 0;
  color: var(--s-600);
  font-size: var(--font-size-small);
  position: relative;
  top: 1px;
  border: none;
  border-radius: var(--border-radius-normal);
  cursor: pointer;
  transition: background 0.3s;
}
.active {
  .select-btn {
    border-color: var(--v-400);
  }
}
.filter-buttons .filter-button.active {
  border-radius: var(--border-radius-normal);
  background-color: var(--white);
  box-shadow: 0 0 7px 0 rgba(109, 109, 141, 0.12);
  animation: tabActive 0.3s ease forwards;
  color: var(--v-400);
}

.filter-buttons .filter-button:disabled {
  color: var(--s-600);
  cursor: not-allowed;
}

.options .disabled {
  background-color: var(--V-50) !important;
  color: var(--s-600) !important;
  cursor: not-allowed !important;
}

.select-menu .options .option .assistants-list {
  color: var(--v-400);
}
.select-menu .options .activeOption {
  background: var(--v-100);
  color: var(--v-400);
}

@keyframes fadeInUp {
  from {
    transform: translate3d(0, 30px, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@keyframes fadeInDown {
  from {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  to {
    transform: translate3d(0, 20px, 0);
    opacity: 0;
  }
}
@keyframes tabActive {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.02);
    background-color: var(--white);
    box-shadow: 0 0 7px 0 rgba(109, 109, 141, 0.12);
  }
}
</style>
