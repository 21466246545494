<template>
  <woot-modal :show.sync="show" :on-close="onClose">
    <div class="column">
      <woot-modal-header
        :header-title="$t('AGENT_MGMT.ADD.TITLE')"
        :header-content="$t('AGENT_MGMT.ADD.DESC')"
      />

      <form class="row" @submit.prevent="addAgent()">
        <div class="medium-12 columns">
          <label :class="{ error: $v.agentName.$error }">
            {{ $t('AGENT_MGMT.ADD.FORM.NAME.LABEL') }}
            <input
              v-model.trim="agentName"
              type="text"
              data-testid="agent-name-input"
              :placeholder="$t('AGENT_MGMT.ADD.FORM.NAME.PLACEHOLDER')"
              @input="$v.agentName.$touch"
            />
          </label>
        </div>
        <div class="medium-12 columns">
          <label :class="{ error: $v.agentType.$error }">
            {{ $t('AGENT_MGMT.ADD.FORM.AGENT_TYPE.LABEL') }}
            <select v-model="agentType" data-testid="agent-role-select">
              <option v-for="role in roles" :key="role.name" :value="role.name">
                {{ role.label }}
              </option>
            </select>
            <span v-if="$v.agentType.$error" class="message">
              {{ $t('AGENT_MGMT.ADD.FORM.AGENT_TYPE.ERROR') }}
            </span>
          </label>
        </div>
        <div class="medium-12 columns">
          <label :class="{ error: $v.agentEmail.$error }">
            {{ $t('AGENT_MGMT.ADD.FORM.EMAIL.LABEL') }}
            <input
              v-model.trim="agentEmail"
              type="text"
              data-testid="agent-email-input"
              :placeholder="$t('AGENT_MGMT.ADD.FORM.EMAIL.PLACEHOLDER')"
              @input="$v.agentEmail.$touch"
            />
          </label>
        </div>
        <div class="modal-footer">
          <div class="medium-12 columns">
            <woot-submit-button
              :disabled="
                $v.agentEmail.$invalid ||
                  $v.agentName.$invalid ||
                  uiFlags.isCreating
              "
              data-testid="submit-agent-button"
              :button-text="$t('AGENT_MGMT.ADD.FORM.SUBMIT')"
              :loading="uiFlags.isCreating"
            />
            <button
              class="button clear"
              data-testid="cancel-agent-button"
              @click.prevent="onClose"
            >
              {{ $t('AGENT_MGMT.ADD.CANCEL_BUTTON_TEXT') }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </woot-modal>
</template>

<script>
import { required, minLength, email } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import onboardingMixin, { ACTIONS } from '../../../../mixins/onboardingMixin';
import alertMixin from '../../../../../shared/mixins/alertMixin';
import metaMixin from 'shared/mixins/metaMixin';

export default {
  mixins: [onboardingMixin, alertMixin, metaMixin],
  props: {
    onClose: {
      type: Function,
      default: () => {},
    },
    pageSize: {
      type: Number,
      default: 20,
    },
  },
  data() {
    return {
      agentName: '',
      agentEmail: '',
      agentType: 'agent',
      vertical: 'bottom',
      horizontal: 'center',
      roles: [
        {
          name: 'administrator',
          label: this.$t('AGENT_MGMT.AGENT_TYPES.ADMINISTRATOR'),
        },
        {
          name: 'agent',
          label: this.$t('AGENT_MGMT.AGENT_TYPES.AGENT'),
        },
      ],
      show: true,
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'agents/getUIFlags',
      current_user: 'getCurrentUser',
    }),
  },
  validations: {
    agentName: {
      required,
      minLength: minLength(1),
    },
    agentEmail: {
      required,
      email,
    },
    agentType: {
      required,
    },
  },

  methods: {
    async addAgent() {
      try {
        const resData = await this.$store.dispatch('agents/create', {
          name: this.agentName,
          email: this.agentEmail,
          role: this.agentType,
        });
        this.showAlert(
          this.$t('AGENT_MGMT.ADD.API.SUCCESS_MESSAGE'),
          'success'
        );
        this.onClose();
        this.execUpdateRecordsAndMetadata({
          action: 'create',
          perPageCount: this.pageSize,
          record: resData,
          moduleName: 'agents',
        });
        this.sendProductFruitsEvent(ACTIONS.AGENT_INVITED);
      } catch (error) {
        const {
          response: { data: { error: errorResponse = '' } = {} } = {},
        } = error;
        let errorMessage = '';
        if (error.response.status === 422) {
          errorMessage = this.$t('AGENT_MGMT.ADD.API.EXIST_MESSAGE');
        } else {
          errorMessage = this.$t('AGENT_MGMT.ADD.API.ERROR_MESSAGE');
        }
        if (errorResponse) {
          this.showAlert(
            errorResponse,
            'error',
            {
              type: 'link',
              to: `/app/accounts/${this.current_user.account_id}/settings/billing`,
              message: this.$t('PRICING_PLANS.ACTIONS.BILLING'),
            },
            this.$t('PRICING_PLANS.ACTIONS.SUBTITLE')
          );
        } else {
          this.showAlert(errorMessage, 'error');
        }
      }
    },
  },
};
</script>
