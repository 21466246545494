<template>
  <div />
</template>

<script>
import { settingsRoleBasedMiddleware } from '../../../middleware';

export default {
  watch: {
    '$route.path': {
      handler() {
        settingsRoleBasedMiddleware.call(this);
      },
      immediate: true,
    },
  },
};
</script>
