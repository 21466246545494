<template>
  <settings-section :title="'SLA'" :sub-title="$t('SLA.SETTINGS.TITLE')">
    <label class="medium-9 columns settings-item">
      <div class="enter-to-send--checkbox">
        <input
          id="enableInactivityTimeout"
          v-model="enableSLA"
          type="checkbox"
          @change="manageSLA"
        />
        <label for="enableInactivityTimeout">
          {{ $t('INBOX_MGMT.EDIT.ENABLE_HMAC.LABEL') }} SLA
        </label>
      </div>

      <p class="help-text">
        {{ $t('SLA.SETTINGS.ENABLE_DISABLE') }}
      </p>
    </label>
    <div v-if="enableSLA">
      <div style="margin-top: 24px;">
        <p class="subtitle">
          SLA
        </p>
        <multiselect
          v-model="selectedSLA"
          :options="optionsSLA"
          track-by="id"
          label="name"
          class="no-margin"
          :multiple="false"
          :close-on-select="true"
          :allow-empty="false"
          placeholder="Choose SLA"
          :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
          :deselect-label="$t('FORMS.MULTISELECT.ENTER_TO_REMOVE')"
          @input="updateValue"
        >
          <template slot="option" slot-scope="props">
            <div v-if="props.option.key === 'add_sla'" class="redirect-wrapper">
              <div>SLA ({{ slaPolicies.length }})</div>
              <div class="new-sla">
                {{ $t('SLA.ADD_ACTION') }}
              </div>
            </div>
            <div v-else>
              <span>
                <span>{{ props.option.name }}</span>
              </span>
            </div>
          </template>
        </multiselect>
      </div>
      <div style="margin-top: 12px;">
        <p class="subtitle">
          Event
        </p>
        <multiselect
          v-model="selectedEvent"
          :options="automationRuleEvents"
          track-by="key"
          label="value"
          class="no-margin"
          :multiple="false"
          :allow-empty="false"
          :close-on-select="true"
          placeholder="Choose Event"
          :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
          :deselect-label="$t('FORMS.MULTISELECT.ENTER_TO_REMOVE')"
        />
      </div>
      <woot-submit-button
        style="margin-top: 24px; margin-bottom: 24px;"
        :button-text="$t('INBOX_MGMT.SETTINGS_POPUP.UPDATE')"
        :disabled="selectedEvent === null || selectedSLA === null"
        @click="addAndEditSLA"
      />
    </div>
  </settings-section>
</template>
<script>
import SettingsSection from '../../../../../components/SettingsSection.vue';
import { mapGetters } from 'vuex';
import { AUTOMATION_RULE_EVENTS } from '../../automation/constants';
import alertMixin from '../../../../../../shared/mixins/alertMixin';

export default {
  components: {
    SettingsSection,
  },
  mixins: [alertMixin],
  data() {
    return {
      enableSLA: false,
      selectedSLA: null,
      selectedEvent: null,
      currentSLAsetting: null,
    };
  },
  computed: {
    ...mapGetters({
      slaPolicies: 'sla/getSLA',
      slaAutomations: 'automations/getAutomationsSLA',
    }),
    optionsSLA() {
      return [{ key: 'add_sla', name: 'Add New SLA' }, ...this.slaPolicies];
    },
    automationRuleEvents() {
      return AUTOMATION_RULE_EVENTS.filter(
        event => event.key !== 'conversation_opened'
      );
    },
  },
  mounted() {
    this.configureSLACondition();
  },
  methods: {
    configureSLACondition() {
      this.currentSLAsetting = this.slaAutomations.find(
        item => item.inbox_id === +this.$route.params?.inboxId
      );
      const currentSLA = this.slaPolicies.find(
        item => item.id === this.currentSLAsetting?.actions[0]?.action_params[0]
      );
      const currentEvent = this.automationRuleEvents.find(
        item => item.key === this.currentSLAsetting?.event_name
      );
      if (this.currentSLAsetting) {
        this.selectedSLA = currentSLA;
        this.selectedEvent = currentEvent;
        this.enableSLA = this.currentSLAsetting?.active;
      }
    },
    async addAndEditSLA() {
      const payload = {
        id: this.currentSLAsetting?.id,
        name: `SLA_settings_${+this.$route.params?.inboxId}`,
        description: 'sla was added from settings',
        event_name: this.selectedEvent.key,
        inbox_id: +this.$route.params?.inboxId,
        conditions: [
          {
            attribute_key: 'inbox_id',
            filter_operator: 'equal_to',
            values: [+this.$route.params?.inboxId],
            custom_attribute_type: '',
          },
        ],
        actions: [
          {
            action_name: 'add_sla',
            action_params: [this.selectedSLA.id],
          },
        ],
      };
      const action = !this.currentSLAsetting?.id
        ? 'automations/create'
        : 'automations/update';
      try {
        await this.$store.dispatch(action, payload);
        const alertText = !this.currentSLAsetting?.id
          ? this.$t('SLA.SETTINGS.CREATE')
          : this.$t('SLA.SETTINGS.UPDATE');
        await this.$store.dispatch('automations/get');
        this.configureSLACondition();
        this.showAlert(alertText, 'success');
      } catch (e) {
        this.showAlert(this.$t('SLA.SETTINGS.UNKNOWN_ERROR'), 'error');
      }
    },
    async manageSLA() {
      try {
        if (this.currentSLAsetting?.id) {
          if (this.selectedEvent?.key && this.selectedSLA?.id) {
            await this.$store.dispatch('automations/update', {
              active: this.enableSLA,
              id: this.currentSLAsetting?.id,
            });
            this.showAlert(
              this.$t('SLA.SETTINGS.ACTIVITY_STATUS', {
                status: !this.enableSLA ? 'disabled' : 'enabled',
              }),
              'success'
            );
          } else {
            this.showAlert(this.$t('SLA.SETTINGS.WARNING_ENABLED'), 'error');
          }
        }
      } catch (e) {
        this.showAlert(this.$t('SLA.SETTINGS.WARNING_ENABLED'), 'error');
      }
    },
    updateValue(item) {
      if (item?.key === 'add_sla') {
        this.$router.push({ name: 'sla_list' });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.new-sla {
  display: flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: var(--d-600);
  box-shadow: var(--shadow-small);
}
.redirect-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
</style>
