/* global axios */
import ApiClient from './ApiClient';

class MacrosAPI extends ApiClient {
  constructor() {
    super('macros', { accountScoped: true });
  }

  executeMacro({ macroId, conversationIds }) {
    return axios.post(`${this.url}/${macroId}/execute`, {
      conversation_ids: conversationIds,
    });
  }

  get(page, search) {
    return axios.get(this.url, {
      params: {
        page,
        ...(search ? { search } : {}),
      },
    });
  }
}

export default new MacrosAPI();
