<template>
  <div class="column content-box">
    <woot-button
      color-scheme="success"
      class-names="button--fixed-top"
      icon="add-circle"
      data-testid="add-agent-button"
      @click="openAddPopup()"
    >
      {{ $t('AGENT_MGMT.HEADER_BTN_TXT') }}
    </woot-button>

    <!-- List Agents -->
    <div class="row table-view">
      <div class="small-8 columns with-right-space">
        <table-header :has-search="true" @applySearch="execApplySearch" />
        <no-result-card
          v-if="currentState === 'noResult'"
          :title="$t('CANNED_MGMT.NOT_FOUND.TITLE')"
        />
        <spinner-hoory v-if="currentState === 'loading'" class="margin-top-2" />
        <template v-if="currentState === 'records'">
          <agent-table
            :items="records"
            :page-size="perPageCount"
            @editAgent="openEditPopup"
            @deleteAgent="openDeletePopup"
          />
          <table-footer
            :current-page="Number(meta.currentPage)"
            :total-count="meta.count"
            :page-size="perPageCount"
            :records-count="records.length"
            @page-change="onPageChange"
          />
        </template>
      </div>
      <div class="small-4 columns">
        <span
          v-dompurify-html="
            useInstallationName(
              $t('AGENT_MGMT.SIDEBAR_TXT'),
              globalConfig.installationName
            )
          "
        />
      </div>
    </div>
    <!-- Add Agent -->
    <woot-modal :show.sync="showAddPopup" :on-close="hideAddPopup">
      <add-agent :on-close="hideAddPopup" :page-size="perPageCount" />
    </woot-modal>
    <!-- Edit Agent -->
    <woot-modal :show.sync="showEditPopup" :on-close="hideEditPopup">
      <edit-agent
        v-if="showEditPopup"
        :id="currentAgent.id"
        :name="currentAgent.name"
        :type="currentAgent.role"
        :email="currentAgent.email"
        :availability="currentAgent.availability_status"
        :on-close="hideEditPopup"
      />
    </woot-modal>
    <!-- Delete Agent -->
    <woot-delete-modal
      :show.sync="showDeletePopup"
      :on-close="closeDeletePopup"
      :on-confirm="confirmDeletion"
      :title="$t('AGENT_MGMT.DELETE.CONFIRM.TITLE')"
      :message="$t('AGENT_MGMT.DELETE.CONFIRM.MESSAGE')"
      :message-value="deleteMessage"
      :confirm-text="deleteConfirmText"
      :reject-text="deleteRejectText"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import globalConfigMixin from 'shared/mixins/globalConfigMixin';
import AddAgent from './AddAgent';
import EditAgent from './EditAgent';
import onboardingMixin, { ACTIONS } from '../../../../mixins/onboardingMixin';
import AgentTable from './AgentTable.vue';
import TableFooter from '../../../../components/widgets/TableFooter.vue';
import alertMixin from 'shared/mixins/alertMixin';
import TableHeader from 'dashboard/components/widgets/TableHeader.vue';
import metaMixin from 'shared/mixins/metaMixin';
import NoResultCard from 'dashboard/components/ui/NoResultCard.vue';
import SpinnerHoory from 'shared/components/SpinnerHoory.vue';

export default {
  components: {
    TableFooter,
    AgentTable,
    AddAgent,
    EditAgent,
    TableHeader,
    NoResultCard,
    SpinnerHoory,
  },
  mixins: [globalConfigMixin, onboardingMixin, alertMixin, metaMixin],
  data() {
    return {
      loading: {},
      showAddPopup: false,
      showDeletePopup: false,
      showEditPopup: false,
      agentAPI: {
        message: '',
      },
      currentAgent: {},
      search: null,
      perPageCount: 20,
    };
  },
  computed: {
    ...mapGetters({
      records: 'agents/getAgents',
      meta: 'agents/getMeta',
      uiFlags: 'agents/getUIFlags',
      currentUserId: 'getCurrentUserID',
      globalConfig: 'globalConfig/get',
    }),
    deleteConfirmText() {
      return `${this.$t('AGENT_MGMT.DELETE.CONFIRM.YES')} ${
        this.currentAgent.name
      }`;
    },
    deleteRejectText() {
      return `${this.$t('AGENT_MGMT.DELETE.CONFIRM.NO')} ${
        this.currentAgent.name
      }`;
    },
    deleteMessage() {
      return ` ${this.currentAgent.name}?`;
    },
  },
  mounted() {
    this.fetchAgents(this.meta.currentPage);
  },
  methods: {
    execApplySearch(data) {
      this.search = data.search;
      this.fetchAgents(1);
    },

    async fetchAgents(page) {
      await this.$store.dispatch('agents/get', { page, search: this.search });
    },
    // Edit Function
    openAddPopup() {
      this.showAddPopup = true;
    },
    onPageChange(page) {
      this.fetchAgents(page);
    },
    hideAddPopup() {
      this.showAddPopup = false;
    },

    // Edit Function
    openEditPopup(agent) {
      this.showEditPopup = true;
      this.currentAgent = agent;
    },
    hideEditPopup() {
      this.showEditPopup = false;
    },

    // Delete Function
    openDeletePopup(agent) {
      this.showDeletePopup = true;
      this.currentAgent = agent;
    },
    closeDeletePopup() {
      this.showDeletePopup = false;
    },
    confirmDeletion() {
      this.loading[this.currentAgent.id] = true;
      this.closeDeletePopup();
      this.deleteAgent(this.currentAgent.id);
    },
    async deleteAgent(id) {
      try {
        await this.$store.dispatch('agents/delete', id);
        this.setAlert(
          this.$t('AGENT_MGMT.DELETE.API.SUCCESS_MESSAGE'),
          'success'
        );
        this.execUpdateRecordsAndMetadata(
          {
            action: 'delete',
            perPageCount: this.perPageCount,
            record: id,
            moduleName: 'agents',
          },
          () => {
            this.fetchAgents(this.meta.currentPage);
          }
        );
        this.sendProductFruitsEvent(ACTIONS.AGENT_DELETED);
      } catch (error) {
        this.setAlert(this.$t('AGENT_MGMT.DELETE.API.ERROR_MESSAGE'), 'error');
      }
    },
    // Show SnackBar
    setAlert(message, type) {
      // Reset loading, current selected agent
      this.loading[this.currentAgent.id] = false;
      this.currentAgent = {};
      // Show message
      this.agentAPI.message = message;
      this.showAlert(message, type);
    },
  },
};
</script>
