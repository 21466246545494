<template>
  <div>
    <woot-modal-header
      :header-title="$t('INTEGRATION_APPS.SAML.MODAL_TITLE')"
      :header-content="$t('INTEGRATION_APPS.SAML.MODAL_DESCRIPTION')"
    />
    <form @submit.prevent="updateSAML">
      <div class="fields-wrapper">
        <label>SSO URL <span class="error">*</span></label>
        <woot-input
          v-model.trim="ssoUrl"
          placeholder="https://mocksaml.com/api/saml/sso"
          :class="{ error: $v.ssoUrl.$error }"
          :error="$v.ssoUrl.$error ? $t('INTEGRATION_APPS.SAML.WRONG_URL') : ''"
          @blur="$v.ssoUrl.$touch"
        />
        <label>Entity ID <span class="error">*</span></label>
        <woot-input
          v-model.trim="entityId"
          placeholder="https://saml.example.com/entityid"
          :class="{ error: $v.entityId.$error }"
          :error="
            $v.entityId.$error ? $t('INTEGRATION_APPS.SAML.WRONG_URL') : ''
          "
          @blur="$v.entityId.$touch"
        />
        <div>
          <label>x.509 Certificate <span class="error">*</span></label>
          <div class="input--button-wrapper">
            <textarea
              v-model.trim="certificate"
              class="textarea"
              :placeholder="$t('INTEGRATION_APPS.SAML.CERTIFICATE_LABEL')"
              @blur="$v.certificate.$touch"
            />
          </div>
        </div>
      </div>
      <div class="footer">
        <woot-button
          class="button clear"
          :is-loading="isLoading"
          @click.prevent="onClose"
        >
          {{ $t('INTEGRATION_APPS.ADD.FORM.CANCEL') }}
        </woot-button>
        <woot-button
          :disabled="
            $v.ssoUrl.$invalid ||
              $v.entityId.$invalid ||
              $v.certificate.$invalid
          "
          :is-loading="isLoading"
          type="submit"
        >
          {{ isEnabled ? 'Edit' : 'Connect' }}
        </woot-button>
      </div>
    </form>
  </div>
</template>
<script>
import { required, url } from 'vuelidate/lib/validators';
import WootButton from '../../../../components/ui/WootButton.vue';
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';

export default {
  components: { WootButton },
  mixins: [alertMixin],
  data() {
    return {
      ssoUrl: '',
      entityId: '',
      certificate: '',
    };
  },
  computed: {
    ...mapGetters({
      samlData: 'integrations/getSAML',
      flags: 'integrations/getUIFlags',
    }),
    isLoading() {
      return this.flags.isUpdating;
    },
    isEnabled() {
      return this.samlData?.saml_enabled;
    },
  },
  mounted() {
    this.ssoUrl = this.samlData?.saml_config?.idp_sso_service_url;
    this.entityId = this.samlData?.saml_config?.idp_entity_id;
    this.certificate = this.samlData?.saml_config?.idp_cert;
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
    async updateSAML() {
      try {
        await this.$store.dispatch('integrations/connectDisconnectSaml', {
          saml_enabled: true,
          idp_entity_id: this.entityId,
          idp_sso_service_url: this.ssoUrl,
          idp_cert: this.certificate,
          identifier: '',
        });
        this.onClose();
        this.showAlert(
          this.$t('INTEGRATION_APPS.SAML.SUCCESS_CONNECT'),
          'success'
        );
      } catch (error) {
        this.showAlert(
          this.$t('INTEGRATION_APPS.SAML.ERROR_MESSAGE'),
          'success'
        );
      }
    },
  },
  validations: {
    ssoUrl: {
      required,
      url,
    },
    entityId: {
      required,
      url,
    },
    certificate: {
      required,
    },
  },
};
</script>
<style lang="scss" scoped>
.fields-wrapper {
  .input--button-wrapper {
    display: flex;
    gap: 10px;
    align-items: center;
  }
}

.error {
  color: var(--r-400);
}
.info-massage {
  color: var(--d-700);
  font-size: var(--font-size-small) !important;
}
.footer {
  display: flex;
  justify-content: end;
  padding-top: var(--space-medium);
  border-top: 1px solid var(--d-600);
  gap: 10px;
  margin-top: var(--space-medium);
}
</style>
