<template>
  <div class="column content-box">
    <div class="small-12 columns integrations-wrap">
      <div class="small-12 columns integration">
        <div class="row">
          <div class="integration--image">
            <img :src="'/dashboard/images/integrations/saml.svg'" />
          </div>
          <div class="integration--type column">
            <h3 class="integration--title">
              SAML
            </h3>
            <p>{{ $t('INTEGRATION_APPS.SAML.DESCRIPTION') }}</p>
            <div v-if="isEnabled" class="url-wrapper">
              <a :href="samlURL" target="_blank">{{ samlURL }}</a>
              <div style="cursor: pointer" @click="handleCopy">
                <fluent-icon icon="clipboard" size="14" class="menu-icon" />
                <span class="text-copy">{{
                  this.$t('CONVERSATION.CONTEXT_MENU.COPY')
                }}</span>
              </div>
            </div>
          </div>
          <div class="small-2 column button-wrap" style="gap: 2px">
            <div v-if="isEnabled">
              <woot-button
                class="nice alert"
                :is-loading="isLoading"
                @click="onDisconnect"
              >
                {{ $t('INTEGRATION_APPS.DISCONNECT.BUTTON_TEXT') }}
              </woot-button>
            </div>
            <div>
              <woot-button
                class="button nice"
                :is-loading="isLoading"
                @click="showConnectModal = true"
              >
                {{
                  isEnabled
                    ? 'Edit'
                    : $t('INTEGRATION_APPS.CONNECT.BUTTON_TEXT')
                }}
              </woot-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <woot-modal :show.sync="showConnectModal" :on-close="closeConnectModal">
      <ModalConnectSAML @close="showConnectModal = false" />
    </woot-modal>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import ModalConnectSAML from './ModalConnectSAML.vue';
import alertMixin from 'shared/mixins/alertMixin';
import { copyTextToClipboard } from 'shared/helpers/clipboard';

export default {
  components: { ModalConnectSAML },
  mixins: [alertMixin],
  data() {
    return {
      showConnectModal: false,
    };
  },
  computed: {
    ...mapGetters({
      samlData: 'integrations/getSAML',
      flags: 'integrations/getUIFlags',
    }),
    isEnabled() {
      return this.samlData?.saml_enabled;
    },
    samlURL() {
      return this.samlData?.saml_url;
    },
    isLoading() {
      return this.flags.isUpdating;
    },
  },
  mounted() {
    if (!this.samlData) {
      this.$store.dispatch('integrations/getSaml');
    }
  },
  methods: {
    async handleCopy() {
      await copyTextToClipboard(this.samlURL);
      this.showAlert(this.$t('CONTACT_PANEL.COPY_SUCCESSFUL'), 'success');
    },
    onDisconnect() {
      const requestBody = {
        ...this.samlData,
        saml_enabled: false,
      };
      this.$store
        .dispatch('integrations/connectDisconnectSaml', requestBody)
        .then(() => {
          this.showAlert(
            this.$t('INTEGRATION_APPS.SAML.SUCCESS_DISCONNECT'),
            'success'
          );
        })
        .catch(() => {
          this.showAlert(
            this.$t('INTEGRATION_APPS.SAML.ERROR_MESSAGE'),
            'error'
          );
        });
    },
    closeConnectModal() {
      this.showConnectModal = false;
    },
  },
};
</script>
<style scoped>
.url-wrapper {
  display: flex;
  align-items: center;
  gap: 5px;
}
.text-copy {
  font-size: var(--font-size-mini);
}
</style>
