<template>
  <div class="event-container">
    <div class="event-container__header">
      <span class="event-title">{{ $t('SLA.EVENTS.TITLE') }}</span>
      <span class="sla-name">
        {{ $t('SLA_REPORTS.DROPDOWN.INPUT_PLACEHOLDER.SLA') }} :
        {{ slaName }}
      </span>
    </div>
    <SLAEventItem
      v-if="frtMisses.length"
      :label="$t('SLA.EVENTS.FRT')"
      :items="frtMisses"
    />
    <SLAEventItem
      v-if="nrtMisses.length"
      :label="$t('SLA.EVENTS.NRT')"
      :items="nrtMisses"
    />
    <!--     TODO: temporary disabled -->

    <!--      <template slot="showMore">-->
    <!--        <div v-if="shouldShowMoreNRTButton" class="show-more-container">-->
    <!--          <woot-button-->
    <!--            size="small"-->
    <!--            :icon="!shouldShowAllNrts ? 'plus-sign' : ''"-->
    <!--            variant="link"-->
    <!--            color-scheme="secondary"-->
    <!--            class="show-more-button"-->
    <!--            @click="toggleShowAllNRT"-->
    <!--          >-->
    <!--            {{-->
    <!--              shouldShowAllNrts-->
    <!--                ? $t('SLA.EVENTS.HIDE', { count: nrtMisses.length })-->
    <!--                : $t('SLA.EVENTS.SHOW_MORE', { count: nrtMisses.length })-->
    <!--            }}-->
    <!--          </woot-button>-->
    <!--        </div>-->
    <!--      </template>-->
    <!--    </SLAEventItem>-->
    <SLAEventItem
      v-if="rtMisses.length"
      :label="$t('SLA.EVENTS.RT')"
      :items="rtMisses"
    />
  </div>
</template>

<script>
import wootConstants from 'dashboard/constants/globals';
import SLAEventItem from './SLAEventItem.vue';

export default {
  components: {
    SLAEventItem,
  },
  props: {
    slaMissedEvents: {
      type: Array,
      required: true,
    },
    slaName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      shouldShowAllNrts: false,
    };
  },
  computed: {
    frtMisses() {
      const { SLA_MISS_TYPES } = wootConstants;
      return this.slaMissedEvents.filter(
        slaEvent => slaEvent.event_type === SLA_MISS_TYPES.FRT
      );
    },
    nrtMisses() {
      const { SLA_MISS_TYPES } = wootConstants;
      const missedEvents = this.slaMissedEvents.filter(
        slaEvent => slaEvent.event_type === SLA_MISS_TYPES.NRT
      );
      return this.shouldShowAllNrts ? missedEvents : missedEvents.slice(0, 6);
    },
    rtMisses() {
      const { SLA_MISS_TYPES } = wootConstants;
      return this.slaMissedEvents.filter(
        slaEvent => slaEvent.event_type === SLA_MISS_TYPES.RT
      );
    },
    shouldShowMoreNRTButton() {
      return this.nrtMisses.length > 6;
    },
  },
  methods: {
    toggleShowAllNRT() {
      this.shouldShowAllNrts = !this.shouldShowAllNrts;
    },
  },
};
</script>
<style scoped lang="scss">
.event-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: var(--white);
  z-index: 999;
  padding: 1rem;
  border: 1px solid var(--d-300);
  width: 330px;
  border-radius: var(--border-radius-medium);
  gap: 0.5rem;
  max-height: 24rem;
  overflow: auto;
  box-shadow: var(--shadow-larger);
  &__header {
    display: flex;
    flex-direction: column;
  }
}

.event-title {
  font-size: var(--font-size-paragraph);
  font-weight: 500;
  color: var(--d-700);
}
.sla-name {
  font-size: var(--font-size-paragraph);
  font-weight: 500;
  color: var(--s-700);
}

.dark-mode .event-title {
  color: #e2e8f0; /* Equivalent to slate-25 */
}

.show-more-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
}

.show-more-button {
  gap: 0.25rem;
  background: transparent;
  text-decoration: none;
}

.show-more-button:hover {
  background-color: transparent;
}
</style>
