import Vue from 'vue';
import {
  SET_TEAM_UI_FLAG,
  CLEAR_TEAMS,
  SET_TEAMS,
  SET_TEAM_ITEM,
  EDIT_TEAM,
  SET_TEAM_META,
} from './types';

export const mutations = {
  [SET_TEAM_UI_FLAG]($state, data) {
    $state.uiFlags = {
      ...$state.uiFlags,
      ...data,
    };
  },

  [CLEAR_TEAMS]: $state => {
    Vue.set($state, 'records', {});
  },

  [SET_TEAM_META]: ($state, data) => {
    const { items_count: count, current_page: currentPage } = data;
    Vue.set($state, 'meta', { count, currentPage });
  },

  [SET_TEAMS]: ($state, data) => {
    data.forEach(team => {
      Vue.set($state.records, team.id, {
        ...($state.records[team.id] || {}),
        ...team,
      });
    });
  },
  [SET_TEAM_ITEM]: ($state, data) => {
    Vue.set($state.records, data.id, {
      ...($state.records[data.id] || {}),
      ...data,
    });
  },

  [EDIT_TEAM]: ($state, data) => {
    Vue.set($state.records, data.id, data);
  },
};
