<template>
  <div class="column content-box">
    <div class="row table-view">
      <div class="small-8 columns with-right-space">
        <table-header
          :filters="filters"
          :has-search="true"
          @applySearch="execApplySearch"
        />
        <no-result-card
          v-if="currentState === 'noResult'"
          :title="$t('CANNED_MGMT.NOT_FOUND.TITLE')"
        />
        <spinner-hoory v-if="currentState === 'loading'" class="margin-top-2" />
        <template v-if="currentState === 'records'">
          <channel-table
            v-if="records.length && !isSearching"
            :items="records"
            @deleteChannel="openDelete"
            @redirectToSettings="redirectToChannelSettings"
          />
          <table-footer
            :current-page="Number(meta.currentPage)"
            :total-count="meta.count"
            :page-size="20"
            :records-count="records.length"
            @page-change="onPageChange"
          />
        </template>
      </div>

      <div class="small-4 columns">
        <span
          v-dompurify-html="
            useInstallationName(
              $t('INBOX_MGMT.SIDEBAR_TXT'),
              globalConfig.installationName
            )
          "
        />
      </div>
    </div>
    <settings
      v-if="showSettings"
      :show.sync="showSettings"
      :on-close="closeSettings"
      :inbox="selectedInbox"
    />

    <woot-confirm-delete-modal
      v-if="showDeletePopup"
      :show.sync="showDeletePopup"
      :title="$t('INBOX_MGMT.DELETE.CONFIRM.TITLE')"
      :description="confirmDescription"
      :message="confirmDeleteMessage"
      :confirm-text="deleteConfirmText"
      :reject-text="deleteRejectText"
      :confirm-value="selectedInbox.name"
      :confirm-place-holder-text="confirmPlaceHolderText"
      @on-confirm="confirmDeletion"
      @on-close="closeDelete"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Settings from './Settings';
import adminMixin from '../../../../mixins/isAdmin';
import billingMixin from 'dashboard/mixins/billing';
import accountMixin from 'dashboard/mixins/account';
import globalConfigMixin from 'shared/mixins/globalConfigMixin';
import noChannelSvg from 'dashboard/assets/icons/call/noChannel.svg';
import ChannelTable from './ChannelTable.vue';
import TableFooter from '../../../../components/widgets/TableFooter.vue';
import TableHeader from 'dashboard/components/widgets/TableHeader.vue';
import SpinnerHoory from 'shared/components/SpinnerHoory.vue';
import NoResultCard from 'dashboard/components/ui/NoResultCard.vue';
import metaMixin from 'shared/mixins/metaMixin';
import alertMixin from 'shared/mixins/alertMixin';

export default {
  components: {
    SpinnerHoory,
    ChannelTable,
    Settings,
    TableFooter,
    TableHeader,
    NoResultCard,
  },
  mixins: [
    adminMixin,
    alertMixin,
    accountMixin,
    globalConfigMixin,
    billingMixin,
    metaMixin,
  ],
  data() {
    return {
      isSearching: false,
      showSettings: false,
      showDeletePopup: false,
      selectedInbox: {},
      confirmDescription: '',
      noChannelSvg,
      search: null,
      selectedInboxTypes: [],
      selectedLabels: [],
      filters: [
        {
          name: 'channelTypes',
          options: [
            {
              id: 'web_widget',
              name: 'Website',
            },
            {
              id: 'api',
              name: 'Api',
            },
            {
              id: 'call',
              name: 'Call',
            },
            {
              id: 'email',
              name: 'Email',
            },
            {
              id: 'line',
              name: 'Line',
            },
            {
              id: 'telegram',
              name: 'Telegram',
            },
            {
              id: 'whatsapp',
              name: 'Whatsapp',
            },
            {
              id: 'facebook',
              name: 'Messenger',
            },
            {
              id: 'sms',
              name: 'SMS',
            },
          ],
          trackBy: 'id',
          label: 'name',
          multiple: true,
          limit: 2,
          placeholder: 'Channel Type',
        },
      ],
      perPageCount: 20,
    };
  },
  computed: {
    ...mapGetters({
      records: 'inboxes/getInboxes',
      meta: 'inboxes/getMeta',
      globalConfig: 'globalConfig/get',
      uiFlags: 'agents/getUIFlags',
    }),
    // Delete Modal
    deleteConfirmText() {
      return `${this.$t('INBOX_MGMT.DELETE.CONFIRM.YES')} ${
        this.selectedInbox.name
      }`;
    },
    deleteRejectText() {
      return `${this.$t('INBOX_MGMT.DELETE.CONFIRM.NO')} ${
        this.selectedInbox.name
      }`;
    },
    confirmDeleteMessage() {
      return `${this.$t('INBOX_MGMT.DELETE.CONFIRM.MESSAGE')} ${
        this.selectedInbox.name
      } ?`;
    },
    confirmPlaceHolderText() {
      return `${this.$t('INBOX_MGMT.DELETE.CONFIRM.PLACE_HOLDER', {
        inboxName: this.selectedInbox.name,
      })}`;
    },
  },
  mounted() {
    this.fetchChannels(this.meta.currentPage);
  },
  methods: {
    execApplySearch(data) {
      this.search = data.search;
      if (data.filters.channelTypes) {
        this.selectedInboxTypes = data.filters.channelTypes.map(item => {
          return item.id;
        });
      }
      this.fetchChannels(1);
    },
    onPageChange(page) {
      this.fetchChannels(page);
    },
    openSettings(inbox) {
      this.showSettings = true;
      this.selectedInbox = inbox;
    },
    closeSettings() {
      this.showSettings = false;
      this.selectedInbox = {};
    },
    redirectToChannelSettings(row) {
      this.$router.push(
        `${this.addAccountScoping(`settings/inboxes/${row.id}`)}`
      );
    },
    async fetchChannels(page) {
      this.isSearching = true;
      await this.$store.dispatch('inboxes/get', {
        page,
        search: this.search,
        inboxes: this.selectedInboxTypes,
      });
      this.isSearching = false;
    },
    async deleteInbox(inbox) {
      try {
        const resData =
          inbox.channel_type === 'Channel::Call'
            ? await this.deleteCallBaseChannel({
                inboxId: inbox.id,
                accountId: this.accountId,
              })
            : await this.$store.dispatch('inboxes/delete', inbox.id);

        this.execUpdateRecordsAndMetadata(
          {
            action: 'delete',
            perPageCount: this.perPageCount,
            record: inbox.id,
            moduleName: 'inboxes',
          },
          () => {
            this.fetchChannels(this.meta.currentPage);
          }
        );

        this.showAlert(
          resData?.message || this.$t('INBOX_MGMT.DELETE.API.SUCCESS_MESSAGE'),
          'success'
        );
      } catch (error) {
        this.showAlert(this.$t('INBOX_MGMT.DELETE.API.ERROR_MESSAGE'), 'error');
      }
    },

    confirmDeletion() {
      this.deleteInbox(this.selectedInbox);
      this.closeDelete();
    },
    openDelete(inbox) {
      this.confirmDescription =
        inbox.channel_type === 'Channel::Call'
          ? this.$t('INBOX_MGMT.DELETE.CONFIRM.CALL_DESCRIPTION', {
              inboxName: inbox.name,
            })
          : '';
      this.showDeletePopup = true;
      this.selectedInbox = inbox;
    },
    closeDelete() {
      this.showDeletePopup = false;
      this.selectedInbox = {};
    },
  },
};
</script>
