<template>
  <mention-box
    :data-loaded="isDataLoaded"
    :items="items"
    @mention-select="handleMentionClick"
  >
    <template slot-scope="{ item }">
      <strong>{{ item.label }}</strong> - {{ item.description }}
    </template>
  </mention-box>
</template>

<script>
import { mapGetters } from 'vuex';
import MentionBox from '../mentions/MentionBox.vue';

export default {
  components: { MentionBox },
  props: {
    searchKey: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isDataLoaded: false,
    };
  },
  computed: {
    ...mapGetters({
      currentChat: 'getSelectedChat',
      cannedMessages: 'getCannedResponses',
    }),
    items() {
      return this.cannedMessages.map(cannedMessage => ({
        label: cannedMessage.short_code,
        key: cannedMessage.short_code,
        description: cannedMessage.content,
      }));
    },
  },
  watch: {
    searchKey() {
      this.fetchCannedResponses();
    },
  },
  async created() {
    this.fetchCannedResponses();
  },
  beforeDestroy() {
    this.$store.commit('SET_CANNED', []);
  },
  methods: {
    fetchCannedResponses() {
      this.$store
        .dispatch('getCannedResponse', {
          search: this.searchKey,
          inbox_id: this.currentChat.inbox_id,
        })
        .then(() => {
          this.isDataLoaded = true;
        });
    },
    handleMentionClick(item = {}) {
      this.$emit('click', item.description);
    },
  },
};
</script>
