<template>
  <div class="woot-table">
    <ve-table
      :fixed-header="true"
      max-height="calc(100vh - 20.6rem)"
      scroll-width="60rem"
      :columns="columns"
      :table-data="items"
      :border-around="false"
    />
  </div>
</template>

<script>
import { VeTable } from 'vue-easytable';
import { mapGetters } from 'vuex';
import accountMixin from 'dashboard/mixins/account';
import adminMixin from '../../../../mixins/isAdmin';

export default {
  components: {
    VeTable,
  },
  mixins: [accountMixin, adminMixin],
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    searchText: {
      type: String,
      default: '',
    },
  },
  computed: {
    checkAdmin() {
      return this.isAdmin;
    },
    ...mapGetters({
      globalConfig: 'globalConfig/get',
    }),
    columns() {
      return [
        {
          field: 'team_name',
          key: 'team_name',
          title: 'Team Names',
          align: 'start',
          renderBodyCell: ({ row }) => (
            <div>
              <span class="agent-name">{row.name}</span>
              <p>{row.description}</p>
            </div>
          ),
        },
        {
          field: 'actions',
          key: 'actions',
          title: 'Actions',
          align: 'right',
          renderBodyCell: ({ row }) => {
            const checkAdmin = this.checkAdmin;
            return (
              <div class="button-wrapper">
                {checkAdmin && (
                  <woot-button
                    v-tooltip={this.$t('INBOX_MGMT.SETTINGS')}
                    variant="smooth"
                    size="tiny"
                    icon="settings"
                    color-scheme="secondary"
                    class-names="grey-btn"
                    onClick={() => this.$emit('redirectToSettings', row)}
                  />
                )}
                {checkAdmin && (
                  <woot-button
                    v-tooltip={this.$t('INBOX_MGMT.DELETE.BUTTON_TEXT')}
                    variant="smooth"
                    color-scheme="alert"
                    size="tiny"
                    class="grey-btn"
                    icon="dismiss-circle"
                    onClick={() => this.$emit('deleteTeam', row)}
                  />
                )}
              </div>
            );
          },
        },
      ];
    },
  },
  methods: {
    twilioChannelName(item) {
      const { medium = '' } = item;
      if (medium === 'whatsapp') return 'WhatsApp';
      return 'Twilio SMS';
    },
  },
};
</script>
<style lang="scss">
@import '../../../../../dashboard/assets/scss/widgets/_woot-tables.scss';
</style>
