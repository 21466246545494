<template>
  <div class="workspace-holder" @click="toggleWorkspace()">
    <div v-if="show_workspaces" class="workspaces-list-holder">
      <button
        v-for="account in currentUser.accounts"
        :key="account.id"
        class="button small clear secondary account-item"
        :class="{ active: currentUser.account_id === account.id }"
        @click="changeAccount(account.id)"
      >
        <span class="button__content">
          {{ account.name }}
        </span>
      </button>
    </div>
    <div class="workspace-data-holder">
      <span class="workspace-title">
        <span> {{ $t('PROFILE_SETTINGS.WORKSPACE') }}:</span> {{ account.name }}
      </span>
      <div class="icon-holder">
        <fluent-icon
          icon="right-carrot"
          size="20"
          class="icon icon--font"
          view-box="0 0 20 5"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  components: {},
  data() {
    return {
      show_workspaces: false,
    };
  },
  computed: {
    ...mapGetters({
      account: 'getCurrentAccount',
      currentUser: 'getCurrentUser',
    }),
  },
  mounted() {},
  methods: {
    toggleWorkspace() {
      this.show_workspaces = !this.show_workspaces;
    },
    changeAccount(accountId) {
      window.location.href = `/app/accounts/${accountId}/dashboard`;
    },
  },
};
</script>

<style>
.workspace-data-holder {
  width: 100%;
  color: var(--v-400);
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-small);
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
}
.workspace-title {
  padding: var(--space-small);
  font-weight: var(--font-weight-normal) !important;
  > span {
    font-weight: var(--font-weight-medium) !important;
  }
}
.icon-holder {
  padding: 0 var(--space-small);
  color: #afafb6;
}
.workspaces-list-holder {
  z-index: var(--z-index-much-higher);
  width: 100%;
  height: 95%;
  overflow-y: auto;
  position: absolute;
  left: calc(var(--space-giga) + 14px);
  top: unset;
  bottom: unset;
  background: var(--white);
  padding: var(--space-smaller);
  border-radius: var(--border-radius-normal);
  box-shadow: 0 0.8rem 1.6rem rgba(50, 50, 93, 0.08),
    0 0.4rem 1.2rem rgba(0, 0, 0, 0.07);
  border: 1px solid var(--s-75);
  .account-item {
    width: 100%;
    text-align: left;
    font-weight: var(--font-weight-medium);
    font-size: var(--font-size-small);
    padding: var(--space-slab) var(--space-two);
    height: unset;
  }
  .active {
    color: var(--v-400) !important;
  }
}
</style>
