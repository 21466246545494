<template>
  <div class="multiselect-wrap--small">
    <multiselect
      v-model="selectedOption"
      class="no-margin"
      :placeholder="$t('TEAM_REPORTS.FILTER_DROPDOWN_LABEL')"
      label="name"
      track-by="id"
      :limit="2"
      :limit-text="count => '+' + count"
      :options="options"
      :option-height="24"
      :show-labels="false"
      @input="handleInput"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ReportsFiltersTeams',
  data() {
    return {
      selectedOption: null,
    };
  },
  computed: {
    ...mapGetters({
      options: 'teams/getTeams',
    }),
  },
  mounted() {
    this.$store.dispatch('teams/get');
  },
  methods: {
    handleInput() {
      this.$emit('team-filter-selection', this.selectedOption);
    },
  },
};
</script>
<style scoped lang="scss">
.no-margin {
  &::v-deep .multiselect__strong {
    margin-right: var(--space-smaller) !important;
    margin-bottom: 0 !important;
    line-height: 0 !important;
    font-weight: 400 !important;
    font-size: var(--font-size-small) !important;
  }
  &::v-deep .multiselect__tag {
    max-width: 150px !important;
    @media screen and (max-width: 1250px) {
      max-width: 80px !important;
    }
  }
}
</style>
