<template>
  <div>
    <ve-table
      :fixed-header="true"
      max-height="calc(100vh - 20.6rem)"
      scroll-width="60rem"
      :columns="columns"
      :table-data="items"
      :border-around="false"
    />
  </div>
</template>

<script>
import { VeTable } from 'vue-easytable';
import messageFormatterMixin from 'shared/mixins/messageFormatterMixin';
import dompurifyMixin from 'shared/mixins/dompurifyMixin';

export default {
  components: {
    VeTable,
  },
  mixins: [messageFormatterMixin, dompurifyMixin],
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    searchText: {
      type: String,
      default: '',
    },
  },
  computed: {
    columns() {
      return [
        {
          field: 'short_code',
          key: 'short_code',
          title: this.$t('CANNED_MGMT.LIST.TABLE_HEADER')[0],
          align: 'left',
          renderBodyCell: ({ row }) =>
            this.searchText
              ? this.renderHighlightedText(row.short_code)
              : row.short_code,
        },
        {
          field: 'content',
          key: 'content',
          title: this.$t('CANNED_MGMT.LIST.TABLE_HEADER')[1],
          align: 'left',
          renderBodyCell: ({ row }) =>
            this.searchText
              ? this.renderHighlightedText(row.content)
              : row.content,
        },
        {
          field: 'channel',
          key: 'channel',
          title: this.$t('CANNED_MGMT.LIST.TABLE_HEADER')[3],
          align: 'left',
          renderBodyCell: ({ row }) =>
            row.inboxes.map(item => item.name).join(', '),
        },
        {
          field: 'actions',
          key: 'actions',
          title: this.$t('CANNED_MGMT.LIST.TABLE_HEADER')[2],
          align: 'right',
          width: 100,
          renderBodyCell: ({ row }) => (
            <div>
              <woot-button
                v-tooltip={this.$t('CANNED_MGMT.EDIT.BUTTON_TEXT')}
                variant="smooth"
                size="tiny"
                color-scheme="secondary"
                icon="edit"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                fill-color="none"
                onClick={() => this.$emit('editCanned', row)}
              />
              <woot-button
                v-tooltip={this.$t('CANNED_MGMT.DELETE.BUTTON_TEXT')}
                variant="smooth"
                color-scheme="alert"
                size="tiny"
                icon="dismiss-circle"
                class-names="grey-btn canned-actions-btn"
                onClick={() => this.$emit('deleteCanned', row)}
              />
            </div>
          ),
        },
      ];
    },
  },
};
</script>
<style lang="scss" scoped>
.canned-actions-btn {
  margin-left: 14px;
}
.message-content::v-deep p,
.message-content::v-deep li::marker {
  color: var(--h-700);
  margin-bottom: var(--space-smaller);
}

.message-content {
  overflow-wrap: break-word;
}

.message-content::v-deep .searchkey--highlight {
  color: var(--v-600);
  font-weight: var(--font-weight-black);
  font-size: var(--font-size-small);
}
</style>
