<template>
  <div class="woot-table">
    <ve-table
      :fixed-header="true"
      max-height="calc(100vh - 20.6rem)"
      scroll-width="60rem"
      :columns="columns"
      :table-data="items"
      :border-around="false"
    />
  </div>
</template>

<script>
import { VeTable } from 'vue-easytable';
import { mapGetters } from 'vuex';
import accountMixin from 'dashboard/mixins/account';
import adminMixin from '../../../../mixins/isAdmin';
import Thumbnail from '../../../../components/widgets/Thumbnail';

export default {
  components: {
    VeTable,
    // eslint-disable-next-line vue/no-unused-components
    Thumbnail,
  },
  mixins: [accountMixin, adminMixin],
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    searchText: {
      type: String,
      default: '',
    },
    loading: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters({
      globalConfig: 'globalConfig/get',
      currentUserId: 'getCurrentUserID',
    }),
    columns() {
      return [
        {
          field: 'icon',
          key: 'icon',
          title: 'Avatar',
          align: 'start',
          width: 90,
          renderBodyCell: ({ row }) => {
            return (
              <div>
                <Thumbnail
                  src={row.thumbnail}
                  class="columns"
                  username={row.name}
                  size="40px"
                  status={row.availability_status}
                />
              </div>
            );
          },
        },
        {
          field: 'details',
          key: 'details',
          title: 'Agent Details',
          align: 'start',
          renderBodyCell: ({ row }) => {
            return (
              <div style="display: flex; flex-direction: column;">
                <span class="agent-name" data-testid="agent-item-name">
                  {row.name}
                </span>
                <span
                  data-testid="agent-item-email"
                  style="displey:block; word-break: break-word;"
                >
                  {row.email}
                </span>
              </div>
            );
          },
        },
        {
          field: 'role',
          key: 'role',
          title: 'Role',
          align: 'start',
          renderBodyCell: ({ row }) => {
            return (
              <div>
                <span class="agent-name" data-testid="agent-item-role">
                  {this.$t(`AGENT_MGMT.AGENT_TYPES.${row.role.toUpperCase()}`)}
                </span>
                {row.confirmed && (
                  <span data-testid="agent-item-verified">
                    {this.$t('AGENT_MGMT.LIST.VERIFIED')}
                  </span>
                )}
                {!row.confirmed && (
                  <span data-testid="agent-item-pending">
                    {this.$t('AGENT_MGMT.LIST.VERIFICATION_PENDING')}
                  </span>
                )}
              </div>
            );
          },
        },
        {
          field: 'actions',
          key: 'actions',
          title: 'Actions',
          align: 'right',
          renderBodyCell: ({ row }) => {
            const showActionEdit = this.showEditAction(row);
            const showActionDelete = this.showDeleteAction(row);
            return (
              <div class="button-wrapper">
                {showActionEdit && (
                  <woot-button
                    v-tooltip={this.$t('AGENT_MGMT.EDIT.BUTTON_TEXT')}
                    variant="smooth"
                    size="tiny"
                    color-scheme="secondary"
                    icon="edit"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    fill-color="none"
                    class-names="grey-btn"
                    data-testid="edit-agent-button"
                    onClick={() => this.$emit('editAgent', row)}
                  />
                )}
                {showActionDelete && (
                  <woot-button
                    v-tooltip={this.$t('AGENT_MGMT.DELETE.BUTTON_TEXT')}
                    variant="smooth"
                    color-scheme="alert"
                    size="tiny"
                    icon="dismiss-circle"
                    class-names="grey-btn"
                    data-testid="open-delete-agent-modal-button"
                    onClick={() => this.$emit('deleteAgent', row)}
                  />
                )}
              </div>
            );
          },
        },
      ];
    },
  },
  methods: {
    showEditAction(agent) {
      return this.currentUserId !== agent.id;
    },
    showDeleteAction(agent) {
      if (this.currentUserId === agent.id) {
        return false;
      }

      if (!agent.confirmed) {
        return true;
      }

      if (agent.role === 'administrator') {
        return this.verifiedAdministrators().length !== 1;
      }
      return true;
    },
    verifiedAdministrators() {
      return this.items.filter(
        agent => agent.role === 'administrator' && agent.confirmed
      );
    },
  },
};
</script>
<style lang="scss">
@import '../../../../../dashboard/assets/scss/widgets/_woot-tables.scss';
</style>
