const DEFAULT_PAGE = 1;

export default {
  computed: {
    pageParameter() {
      const selectedPageNumber = Number(this.$route.query?.page);
      return !Number.isNaN(selectedPageNumber) &&
        selectedPageNumber >= DEFAULT_PAGE
        ? selectedPageNumber
        : DEFAULT_PAGE;
    },
  },
  methods: {
    updatePageParams(page, searchKey = '') {
      const params = new URLSearchParams({ page });
      if (searchKey) params.set('search', searchKey);
      window.history.pushState(
        {},
        '',
        `${this.$route.path}?${params.toString()}`
      );
    },
  },
};
