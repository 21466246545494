<template>
  <div class="row">
    <div class="integration--image">
      <img :src="'/dashboard/images/integrations/saml.svg'" loading="eager" />
    </div>
    <div class="integration--type column">
      <h3 class="integration--title">
        SAML
      </h3>
      <p>{{ $t('INTEGRATION_APPS.SAML.DESCRIPTION') }}</p>
      <div v-if="isEnabled" class="url-wrapper">
        <a :href="samlURL" target="_blank">{{ samlURL }}</a>
        <div style="cursor: pointer" @click="handleCopy">
          <fluent-icon icon="clipboard" size="14" class="menu-icon" />
          <span class="text-copy">{{
            this.$t('CONVERSATION.CONTEXT_MENU.COPY')
          }}</span>
        </div>
      </div>
    </div>
    <div class="small-2 column button-wrap">
      <woot-label :title="labelText" :color-scheme="labelColor" />
    </div>
    <div class="small-2 column button-wrap">
      <router-link
        :to="frontendURL(`accounts/${accountId}/settings/applications/saml`)"
      >
        <woot-button icon="settings">
          {{ $t('INTEGRATION_APPS.CONFIGURE') }}
        </woot-button>
      </router-link>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { frontendURL } from '../../../../helper/URLHelper';
import WootLabel from 'dashboard/components/ui/Label';
import { copyTextToClipboard } from 'shared/helpers/clipboard';
import alertMixin from 'shared/mixins/alertMixin';

export default {
  components: {
    WootLabel,
  },
  mixins: [alertMixin],
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
      samlData: 'integrations/getSAML',
    }),
    isEnabled() {
      return this.samlData?.saml_enabled;
    },
    samlURL() {
      return this.samlData?.saml_url;
    },
    labelText() {
      return this.samlData?.saml_enabled
        ? this.$t('INTEGRATION_APPS.STATUS.ENABLED')
        : this.$t('INTEGRATION_APPS.STATUS.DISABLED');
    },
    labelColor() {
      return this.samlData?.saml_enabled ? 'success' : 'secondary';
    },
  },
  mounted() {
    this.$store.dispatch('integrations/getSaml');
  },
  methods: {
    frontendURL,
    async handleCopy() {
      await copyTextToClipboard(this.samlURL);
      this.showAlert(this.$t('CONTACT_PANEL.COPY_SUCCESSFUL'), 'success');
    },
  },
};
</script>
<style scoped>
.url-wrapper {
  display: flex;
  align-items: center;
  gap: 5px;
}
.text-copy {
  font-size: var(--font-size-mini);
}
</style>
