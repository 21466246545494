<template>
  <div class="wizard-body columns small-9">
    <empty-state
      :title="'🎉 ' + $t('INBOX_MGMT.FINISH.TITLE')"
      :message="message"
      :button-text="$t('INBOX_MGMT.FINISH.BUTTON_TEXT')"
    >
      <div class="medium-12 columns text-center">
        <div class="website--code">
          <woot-code
            v-if="currentInbox.web_widget_script"
            :script="scriptWithSettings"
          />
        </div>
        <div class="medium-6 small-offset-3">
          <woot-code
            v-if="isATwilioInbox"
            lang="html"
            :script="currentInbox.callback_webhook_url"
          />
        </div>
        <div v-if="isWhatsAppCloudInbox" class="medium-6 small-offset-3">
          <p class="config--label">
            {{ $t('INBOX_MGMT.ADD.WHATSAPP.API_CALLBACK.WEBHOOK_URL') }}
          </p>
          <woot-code lang="html" :script="currentInbox.callback_webhook_url" />
          <p class="config--label">
            {{
              $t(
                'INBOX_MGMT.ADD.WHATSAPP.API_CALLBACK.WEBHOOK_VERIFICATION_TOKEN'
              )
            }}
          </p>
          <woot-code
            lang="html"
            :script="currentInbox.provider_config.webhook_verify_token"
          />
        </div>
        <div class="medium-6 small-offset-3">
          <woot-code
            v-if="isALineInbox"
            lang="html"
            :script="currentInbox.callback_webhook_url"
          />
        </div>
        <div class="medium-6 small-offset-3">
          <woot-code
            v-if="isASmsInbox"
            lang="html"
            :script="currentInbox.callback_webhook_url"
          />
        </div>
        <div v-if="isACallInbox" class="medium-6 small-offset-3">
          <!-- TODO: Only show this if it's a custom provider using query parameters -->
          <p class="config--label">
            To complete the configuration, set the following URL as a webhook on
            the Twilio dashboard for handling incoming calls:
          </p>
          <woot-code lang="html" :script="currentInbox.call_webhook_url" />
          <p class="config--label">
            for detailed instructions, please refer to
            <a
              target="_blank"
              href="https://www.twilio.com/docs/voice/tutorials/how-to-respond-to-incoming-phone-calls/ruby#buy-and-configure-a-phone-number"
            >
              Twilio's documentation.
            </a>
          </p>
        </div>
        <div
          v-if="isAEmailInbox && !currentInbox.provider"
          class="medium-6 small-offset-3"
        >
          <woot-code lang="html" :script="currentInbox.forward_to_email" />
        </div>
        <div
          v-if="isAEmailInbox && !currentInbox.provider && need_dns"
          class="medium-12"
        />
        <div class="footer">
          <router-link
            class="button hollow primary settings-button"
            :to="{
              name: 'settings_inbox_show',
              params: { inboxId: this.$route.params.inbox_id },
            }"
          >
            {{ $t('INBOX_MGMT.FINISH.MORE_SETTINGS') }}
          </router-link>
          <router-link
            class="button success"
            :to="{
              name: 'inbox_dashboard',
              params: { inboxId: this.$route.params.inbox_id },
            }"
          >
            {{ $t('INBOX_MGMT.FINISH.BUTTON_TEXT') }}
          </router-link>
        </div>
      </div>
    </empty-state>
  </div>
</template>

<script>
import configMixin from 'shared/mixins/configMixin';
import EmptyState from '../../../../components/widgets/EmptyState';
import utils from './utils';
export default {
  components: {
    EmptyState,
  },
  mixins: [configMixin],
  computed: {
    utils() {
      return utils;
    },
    currentInbox() {
      return this.$store.getters['inboxes/getInbox'](
        this.$route.params.inbox_id
      );
    },
    scriptWithSettings() {
      return this.currentInbox.web_widget_script;
    },
    isATwilioInbox() {
      return this.currentInbox.channel_type === 'Channel::TwilioSms';
    },
    isAEmailInbox() {
      return this.currentInbox.channel_type === 'Channel::Email';
    },
    isALineInbox() {
      return this.currentInbox.channel_type === 'Channel::Line';
    },
    isASmsInbox() {
      return this.currentInbox.channel_type === 'Channel::Sms';
    },
    isACallInbox() {
      return this.currentInbox.channel_type === 'Channel::Call';
    },
    isWhatsAppCloudInbox() {
      return (
        this.currentInbox.channel_type === 'Channel::Whatsapp' &&
        this.currentInbox.provider === 'whatsapp_cloud'
      );
    },
    need_dns() {
      return utils.check_need_dns(this.currentInbox.email);
    },
    message() {
      if (this.isATwilioInbox) {
        return `${this.$t('INBOX_MGMT.FINISH.MESSAGE')}. ${this.$t(
          'INBOX_MGMT.ADD.TWILIO.API_CALLBACK.SUBTITLE'
        )}`;
      }

      if (this.isASmsInbox) {
        return `${this.$t('INBOX_MGMT.FINISH.MESSAGE')}. ${this.$t(
          'INBOX_MGMT.ADD.SMS.BANDWIDTH.API_CALLBACK.SUBTITLE'
        )}`;
      }

      if (this.isALineInbox) {
        return `${this.$t('INBOX_MGMT.FINISH.MESSAGE')}. ${this.$t(
          'INBOX_MGMT.ADD.LINE_CHANNEL.API_CALLBACK.SUBTITLE'
        )}`;
      }

      if (this.isWhatsAppCloudInbox) {
        return `${this.$t('INBOX_MGMT.FINISH.MESSAGE')}. ${this.$t(
          'INBOX_MGMT.ADD.WHATSAPP.API_CALLBACK.SUBTITLE'
        )}`;
      }

      if (this.isAEmailInbox && !this.currentInbox.provider) {
        return this.$t('INBOX_MGMT.ADD.EMAIL_CHANNEL.FINISH_MESSAGE');
      }

      if (this.currentInbox.web_widget_script) {
        return this.$t('INBOX_MGMT.FINISH.WEBSITE_SUCCESS');
      }

      return this.$t('INBOX_MGMT.FINISH.MESSAGE');
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.website--code {
  margin: $space-normal auto;
  max-width: 70%;
}

.footer {
  display: flex;
  justify-content: center;
}

.settings-button {
  margin-right: var(--space-small);
}

.config--label {
  color: var(--b-600);
  font-weight: var(--font-weight-medium);
  margin-top: var(--space-large);
}

.empty-state {
  padding: var(--space-two) !important;
}

::v-deep .empty-state .html {
  padding-right: 70px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

::v-deep .title {
  color: var(--s-800) !important;
}

.message {
  padding: 20px;
  margin-bottom: 20px;
  text-align: justify;

  .config_item {
    font-size: var(--font-size-small);
  }
}
</style>
