/* global axios */
import ApiClient from './ApiClient';

class LabelsAPI extends ApiClient {
  constructor() {
    super('labels', { accountScoped: true });
  }

  // eslint-disable-next-line class-methods-use-this
  get cacheModelName() {
    return 'label';
  }

  get(page, search) {
    return axios.get(this.url, {
      params: {
        page,
        ...(search ? { search } : {}),
      },
    });
  }
}

export default new LabelsAPI();
