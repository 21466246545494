<template>
  <header class="table-header">
    <search-input
      v-if="hasSearch"
      class="table-header-filter-item"
      :value="searchValue"
      :placeholder="placeholder"
      @update:value="handleChange"
    />
    <multiselect
      v-for="filter in filters"
      :key="filter.name"
      v-model="selectedFilters[filter.name]"
      :options="filter.options"
      :track-by="filter.trackBy"
      :label="filter.label"
      :limit="filter.limit"
      :limit-text="count => '+' + count"
      class="no-margin table-header-filter-item"
      :multiple="filter.multiple"
      :close-on-select="setBooleans(filter, 'closeOnSelect')"
      :clear-on-select="setBooleans(filter, 'clearOnSelect')"
      :internal-search="setBooleans(filter, 'internalSearch')"
      :show-labels="setBooleans(filter, 'showLabels')"
      :searchable="setBooleans(filter, 'searchable')"
      :preserve-search="setBooleans(filter, 'preserveSearch')"
      :hide-selected="setBooleans(filter, 'hideSelected')"
      :placeholder="filter.placeholder"
      selected-label
      :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
      :deselect-label="$t('FORMS.MULTISELECT.ENTER_TO_REMOVE')"
      @input="filterSelected"
    >
      <template slot="tag" slot-scope="props">
        <MultiselectCustomTag
          :option="props.option"
          @remove="props.remove(props.option.id)"
        />
      </template>
    </multiselect>
  </header>
</template>

<script>
import MultiselectCustomTag from 'dashboard/components/MultiselectCustomTag.vue';
import SearchInput from 'dashboard/components/ui/SearchInput.vue';
import _ from 'lodash';

export default {
  components: {
    SearchInput,
    MultiselectCustomTag,
  },
  props: {
    filters: {
      type: Array,
      default: () => [],
    },
    hasSearch: {
      type: Boolean,
      default: true,
    },
    initialSearchValue: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: 'Search',
    },
  },
  data() {
    return {
      searchValue: this.initialSearchValue,
      selectedFilters: [],
      multiselectDefaultProps: {
        hideSelected: false,
        closeOnSelect: false,
        clearOnSelect: true,
        internalSearch: true,
        showLabels: true,
        searchable: true,
        preserveSearch: false,
      },
    };
  },
  methods: {
    setBooleans(filter, key) {
      return filter[key] !== undefined
        ? filter[key]
        : this.multiselectDefaultProps[key];
    },
    handleChange: _.debounce(function handleSearchChange(val) {
      this.searchValue = val;
      this.$emit('applySearch', {
        search: this.searchValue,
        filters: this.selectedFilters,
      });
    }, 500),
    filterSelected() {
      this.$emit('applySearch', {
        search: this.searchValue,
        filters: this.selectedFilters,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.table-header {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  .table-header-filter-item {
    flex: 1 1 calc(100% / 2 - 10px);
    max-width: calc(100% / 2 - 10px);
    text-align: center;
  }

  .container:has(.table-header-filter-item:nth-child(3)):not(:has(.table-header-filter-item:nth-child(4)))
    .table-header-filter-item {
    flex: 1 1 calc(100% / 3 - 10px);
    max-width: calc(100% / 3 - 10px);
  }
  .container:has(.table-header-filter-item:nth-child(4)):not(:has(.table-header-filter-item:nth-child(5)))
    .table-header-filter-item {
    flex: 1 1 calc(50% - 10px);
    max-width: calc(50% - 10px);
  }

  .container:has(.table-header-filter-item:nth-child(5)):not(:has(.table-header-filter-item:nth-child(6)))
    .table-header-filter-item:nth-child(-n + 3) {
    flex: 1 1 calc(100% / 3 - 10px);
    max-width: calc(100% / 3 - 10px);
  }
  .container:has(.table-header-filter-item:nth-child(5)):not(:has(.table-header-filter-item:nth-child(6)))
    .table-header-filter-item:nth-child(n + 4) {
    flex: 1 1 calc(50% - 10px);
    max-width: calc(50% - 10px);
  }

  .container:has(.table-header-filter-item:nth-child(6))
    .table-header-filter-item {
    flex: 1 1 calc(100% / 3 - 10px);
    max-width: calc(100% / 3 - 10px);
  }
  .multiselect-channels {
    height: 44px;
    border-radius: 12px;
    background: #fff;
    box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, 0.1);
  }
  .no-margin {
    margin-bottom: var(--space-small);
    &::v-deep .multiselect__strong {
      margin-right: var(--space-smaller) !important;
      margin-bottom: 0 !important;
      line-height: 0 !important;
      font-weight: 400 !important;
      font-size: var(--font-size-small) !important;
    }
    &::v-deep.multiselect .multiselect__tags {
      display: flex !important;
      align-items: center !important;
      border-radius: 12px !important;
      .multiselect__tags-wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 0;
      }
    }
    &::v-deep.multiselect__tags {
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
    }
    &::v-deep .multiselect__tag {
      max-width: 150px !important;
      @media screen and (max-width: 1250px) {
        max-width: 80px !important;
      }
    }
  }
}
</style>
