import { frontendURL } from '../helper/URLHelper';

export function settingsRoleBasedMiddleware() {
  if (!/settings$/.test(this.$route.path)) {
    return;
  }

  const isAdministrator =
    this.$store.getters.getCurrentRole === 'administrator';

  this.$router.push(
    frontendURL(
      `accounts/${this.$route.params.accountId}/settings/${
        isAdministrator ? 'general' : 'canned-response'
      }`
    )
  );
}
