<template>
  <div
    v-if="hasSlaThreshold"
    class="sla-container"
    :style="`${slaEvents.length ? 'cursor: pointer;' : ''}`"
    :class="showExtendedInfo ? 'extended-info' : 'default-info'"
  >
    <div
      class="sla-inner-container"
      :class="showExtendedInfo ? 'extended-padding' : 'default-padding'"
      @mouseover="openSlaPopover"
    >
      <div
        class="sla-icon-container"
        :class="showExtendedInfo && 'extended-border'"
        @mouseover="openSlaPopover"
        @mouseleave="closeSlaPopover"
      >
        <img
          v-if="isSlaMissed"
          src="~assets/icons/fire.svg"
          loading="eager"
          class="flex-shrink-0"
          alt="missed icon"
        />
        <img
          v-if="!isSlaMissed"
          src="~assets/icons/timer.svg"
          loading="eager"
          class="flex-shrink-0"
          width="16"
          height="16"
          alt="missed icon"
        />
        <span
          v-if="showExtendedInfo"
          class="sla-status-text"
          :class="slaTextStyles"
        >
          {{ slaStatusText }}
        </span>
      </div>
      <span
        v-show="!isSlaMissed"
        class="sla-threshold-text"
        :class="[
          slaTextStyles,
          showExtendedInfo && 'extended-threshold-padding',
        ]"
      >
        {{ slaStatus.threshold }}
      </span>
    </div>
    <SLA-popover-card
      v-if="showSlaPopoverCard"
      :sla-missed-events="slaEvents"
      :sla-name="currentSlaName"
      :class="{
        'popover-header': showExtendedInfo,
        'popover-card': !showExtendedInfo,
      }"
    />
  </div>
</template>

<script>
import SLAPopoverCard from './SLAPopoverCard.vue';
import { evaluateSLAStatus } from '../helpers/sla';
import { mapGetters } from 'vuex';

const REFRESH_INTERVAL = 3000;

export default {
  components: {
    SLAPopoverCard,
  },
  props: {
    chat: {
      type: Object,
      default: () => ({}),
    },
    showExtendedInfo: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      timer: null,
      showSlaPopover: false,
      slaStatus: {
        threshold: null,
        isSlaMissed: false,
        type: null,
        icon: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      slaData: 'sla/getSLA',
    }),
    slaPolicyId() {
      return this.chat?.sla_policy_id;
    },
    currentSlaName() {
      const sla = this.slaData?.find(i => i?.id === this.slaPolicyId);
      return sla?.name || '';
    },
    appliedSLA() {
      return this.chat?.applied_sla;
    },
    slaEvents() {
      return this.chat?.sla_events;
    },
    hasSlaThreshold() {
      return this.slaStatus?.threshold;
    },
    isSlaMissed() {
      return this.slaStatus?.isSlaMissed;
    },
    slaTextStyles() {
      return this.isSlaMissed ? 'text-red' : 'text-yellow';
    },
    slaStatusText() {
      const upperCaseType = this.slaStatus?.type?.toUpperCase();
      const statusKey = this.isSlaMissed ? 'MISSED' : 'DUE';

      return this.$t(`CONVERSATION.HEADER.SLA_STATUS.${upperCaseType}`, {
        status: this.$t(`CONVERSATION.HEADER.SLA_STATUS.${statusKey}`),
      });
    },
    showSlaPopoverCard() {
      return this.showSlaPopover && this.slaEvents.length;
    },
  },
  watch: {
    chat() {
      this.updateSlaStatus();
    },
  },
  mounted() {
    this.updateSlaStatus();
    this.createTimer();
  },
  beforeDestroy() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  },
  methods: {
    createTimer() {
      this.timer = setTimeout(() => {
        this.updateSlaStatus();
        this.createTimer();
      }, REFRESH_INTERVAL);
    },
    updateSlaStatus() {
      this.slaStatus = evaluateSLAStatus({
        appliedSla: this.appliedSLA,
        chat: this.chat,
      });
    },
    openSlaPopover() {
      this.showSlaPopover = true;
    },
    closeSlaPopover() {
      this.showSlaPopover = false;
    },
  },
};
</script>

<style scoped lang="scss">
.sla-container {
  position: relative;
  display: flex;
  width: fit-content;
  align-items: center;
  min-width: fit-content;
  margin-right: 5px;
}

.sla-container {
  .default-info {
    height: 20px;
    border-radius: 4px;
  }
  .extended-info {
    height: 26px;
    border-radius: 8px;
  }
}

.sla-inner-container {
  display: flex;
  align-items: center;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  .default-padding {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    gap: 0.25rem;
  }
  .extended-padding {
    padding-left: 0.375rem;
    padding-right: 0.375rem;
  }
}

.sla-icon-container {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  .extended-border {
    padding-left: 0.375rem;
    padding-right: 0.375rem;
    border-right: 1px solid #f1f5f9;
    border-left: 1px solid #f1f5f9;
  }
}

.sla-status-text {
  font-size: 12px;
  font-weight: 500;
}

.sla-threshold-text {
  font-size: 12px;
  font-weight: 500;
}

.extended-threshold-padding {
  padding-left: 0.375rem;
  padding-right: 0.375rem;
}

.popover-header {
  right: 0;
  top: 2.75rem;
}
.popover-card {
  top: 2.75rem;
  left: -30px;
}

.text-red {
  color: var(--r-500);
}
.text-yellow {
  color: var(--o-400);
}
</style>
