<template>
  <div class="wrapper">
    <div ref="timelineContainer" class="activity-timeline">
      <template v-for="(activity, index) in data.activity">
        <div
          v-if="activity && activity.to"
          :key="index"
          class="activity"
          :style="getActivityStyle(activity)"
          @mouseenter="setHoveredPosition($event, index)"
          @mouseleave="hovered = null"
        >
          <div
            v-if="hovered === index"
            class="activity-details"
            :style="activityDetailsStyle"
          >
            <div
              class="online-circle circle"
              :class="activities[activity.status].cssClass"
            />
            <div>
              <div class="fs-default">
                {{ activities[activity.status].label }}
                {{ calculateTimeDifference(activity.from, activity.to) }}
              </div>
              <div class="fs-default">
                {{ formatTimeRange(activity.from, activity.to) }}
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
    <div class="duration-holder">
      <div class="online duration-item">
        <div class="online-circle circle" />
        <div>
          {{ this.$t('AGENT_REPORTS.AGENTS_ACTIVITY.ONLINE') }}
          <span>
            {{
              statusDurations['0'] !== undefined
                ? statusDurations['0']
                : '0h 0min'
            }}
          </span>
        </div>
      </div>
      <div class="busy duration-item">
        <div class="busy-circle circle" />
        <div>
          {{ this.$t('AGENT_REPORTS.AGENTS_ACTIVITY.BUSY') }}
          <span>
            {{
              statusDurations['2'] !== undefined
                ? statusDurations['2']
                : '0h 0min'
            }}
          </span>
        </div>
      </div>
      <div class="offline duration-item">
        <div class="offline-circle circle" />
        <div>
          {{ this.$t('AGENT_REPORTS.AGENTS_ACTIVITY.OFFLINE') }}
          <span>
            {{
              statusDurations['1'] !== undefined
                ? statusDurations['1']
                : '0h 0min'
            }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => {
        return {
          activity: [],
        };
      },
    },
  },
  data() {
    return {
      statusDurations: [],
      hovered: null,
      hoveredPosition: { top: 0, left: 0 },
      activities: [
        {
          label: this.$t('AGENT_REPORTS.AGENTS_ACTIVITY.ONLINE'),
          cssClass: 'online-circle',
        },
        {
          label: this.$t('AGENT_REPORTS.AGENTS_ACTIVITY.OFFLINE'),
          cssClass: 'offline-circle',
        },
        {
          label: this.$t('AGENT_REPORTS.AGENTS_ACTIVITY.BUSY'),
          cssClass: 'busy-circle',
        },
      ],
    };
  },
  computed: {
    activityDetailsStyle() {
      return {
        position: 'fixed',
        top: `${this.hoveredPosition.top - 65}px`,
        left: `${this.hoveredPosition.left - 205}px`,
        zIndex: 1000,
      };
    },
  },
  mounted() {
    this.calculateTotalDurations();
  },
  methods: {
    setHoveredPosition(event, index) {
      this.hovered = index;
      this.hoveredPosition = {
        top: event.clientY + window.scrollY,
        left: event.clientX + window.scrollX,
      };
    },

    calculateTimeDifference(startTime, endTime) {
      const diffInMinutes = Math.floor(
        (new Date(endTime) - new Date(startTime)) / 60000
      );
      const hours = Math.floor(diffInMinutes / 60);
      const minutes = diffInMinutes % 60;
      return hours ? `${hours}h ${minutes}min` : `${minutes}min`;
    },

    formatTimeRange(startTime, endTime) {
      const formatTime = dateStr => {
        const [hour, minute] = dateStr
          .substring(11, 16)
          .split(':')
          .map(Number);
        const ampm = hour >= 12 ? 'PM' : 'AM';
        return `${hour % 12 || 12}:${minute
          .toString()
          .padStart(2, '0')} ${ampm}`;
      };
      return `${formatTime(startTime)} - ${formatTime(endTime)}`;
    },

    getActivityStyle(activity) {
      const [
        fromTime,
        ,
        fromTimestamp,
        toTimestamp,
      ] = this.getFromAndToDateTimes(activity.from, activity.to);
      const midnightTimestamp = new Date(
        fromTime.getUTCFullYear(),
        fromTime.getUTCMonth(),
        fromTime.getUTCDate(),
        0,
        0,
        0
      );
      const totalDuration = toTimestamp - fromTimestamp;
      const elapsedFromMidnight = fromTimestamp - midnightTimestamp;
      const leftPosition = (elapsedFromMidnight / (24 * 60 * 60 * 1000)) * 100;
      const width = (totalDuration / (24 * 60 * 60 * 1000)) * 100;
      let color = '';
      switch (activity.status) {
        case 0:
          color = '#5EEDAB'; // Green for online
          break;
        case 1:
          color = '#AFAFB6'; // Red for offline
          break;
        case 2:
          color = '#FFB84D'; // Yellow for busy
          break;
        default:
          color = '#3498db'; // Default color
          break;
      }
      return {
        left: `${leftPosition}%`,
        width: `${width}%`,
        backgroundColor: color,
      };
    },
    calculateDuration(from, to) {
      const [, , fromTimestamp, toTimestamp] = this.getFromAndToDateTimes(
        from,
        to
      );
      return toTimestamp - fromTimestamp;
    },
    formatDuration(milliseconds) {
      const hours = Math.floor(milliseconds / (1000 * 60 * 60));
      const minutes = Math.floor(
        (milliseconds % (1000 * 60 * 60)) / (1000 * 60)
      );
      return `${hours}h ${minutes}min`;
    },
    calculateTotalDurations() {
      const statusDurations = {};

      this.data.activity.forEach(activity => {
        if (!activity.to) return;
        const status = activity.status.toString();
        const duration = this.calculateDuration(activity.from, activity.to);

        if (!statusDurations[status]) {
          statusDurations[status] = duration;
        } else {
          statusDurations[status] += duration;
        }
      });
      // eslint-disable-next-line guard-for-in,no-restricted-syntax
      for (const status in statusDurations) {
        statusDurations[status] = this.formatDuration(statusDurations[status]);
      }
      this.statusDurations = statusDurations;
    },
    getFromAndToDateTimes(from, to) {
      let fromTime;
      let toTime;
      let fromTimestamp;
      let toTimestamp;
      fromTime = new Date(from);
      toTime = new Date(to);
      if (to === null) {
        toTime = new Date();
        toTimestamp = new Date(
          toTime.getFullYear(),
          toTime.getMonth(),
          toTime.getDate(),
          toTime.getHours(),
          toTime.getMinutes(),
          toTime.getSeconds()
        );
      } else {
        toTimestamp = new Date(
          toTime.getUTCFullYear(),
          toTime.getUTCMonth(),
          toTime.getUTCDate(),
          toTime.getUTCHours(),
          toTime.getUTCMinutes(),
          toTime.getUTCSeconds()
        );
      }
      fromTimestamp = new Date(
        fromTime.getUTCFullYear(),
        fromTime.getUTCMonth(),
        fromTime.getUTCDate(),
        fromTime.getUTCHours(),
        fromTime.getUTCMinutes(),
        fromTime.getUTCSeconds()
      );
      return [fromTime, toTime, fromTimestamp, toTimestamp];
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1677px;
  margin: 0 20px;
  .activity-details {
    position: fixed;
    transition: opacity 0.3s;
    opacity: 0;
    height: 70px;
    width: 210px;
    border-radius: 15px;
    padding: 8px;
    background: #f2f2f2;
    display: flex;
    align-items: start;
    justify-content: start;
    .circle {
      margin-top: 7px;
      margin-right: 8px;
    }
  }
  .activity:hover .activity-details {
    opacity: 1;
  }
  .activity-timeline {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 10px;
    width: 100%;
    margin-bottom: 4px;
    background: #f5f4fa;
    border-radius: 10px;
    overflow: hidden;
    .activity {
      position: absolute;
      height: 10px;
      width: 100%;
      background-color: #3498db;
      border-radius: 4px;
    }
  }
  .duration-holder {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 300px;
    user-select: none;
    font-size: 12px;
    font-weight: 500;
    .duration-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      min-width: 150px;
      color: #545454;
      > div:last-child span {
        color: #303030;
        font-weight: 600;
      }
    }
  }
  .circle {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    margin-right: 5px;
  }
  .online-circle {
    background-color: #5eedab;
  }
  .offline-circle {
    background-color: #afafb6;
  }
  .busy-circle {
    background-color: #ffb84d;
  }
}
</style>
