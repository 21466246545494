<template>
  <div :key="option.id" class="multiselect__tags-wrap">
    <span class="multiselect__tag">
      <span :title="option.name">{{ option.name }}</span>
      <i
        :tabindex="option.id"
        class="multiselect__tag-icon"
        @click="$emit('remove')"
      />
    </span>
  </div>
</template>
<script>
export default {
  props: {
    option: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
