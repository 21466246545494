<template>
  <div class="column content-box">
    <router-link
      :to="addAccountScoping('settings/macros/new')"
      class="button success button--fixed-top"
    >
      <fluent-icon icon="add-circle" />
      <span class="button__content">
        {{ $t('MACROS.HEADER_BTN_TXT') }}
      </span>
    </router-link>
    <div class="row table-view">
      <div class="small-8 columns with-right-space">
        <table-header :has-search="true" @applySearch="execApplySearch" />
        <no-result-card
          v-if="currentState === 'noResult'"
          :title="$t('CANNED_MGMT.NOT_FOUND.TITLE')"
        />
        <spinner-hoory v-if="currentState === 'loading'" class="margin-top-2" />
        <template v-if="currentState === 'records'">
          <macros-table
            v-if="!uiFlags.isFetching && records.length"
            :items="records"
            @editMacro="redirectToChannelSettings"
            @deleteMacro="openDeletePopup"
          />
          <table-footer
            :current-page="Number(meta.currentPage)"
            :total-count="meta.count"
            :page-size="perPageCount"
            :records-count="records.length"
            @page-change="onPageChange"
          />
        </template>
      </div>
      <div class="small-4 columns">
        <span v-dompurify-html="$t('MACROS.SIDEBAR_TXT')" />
      </div>
    </div>
    <woot-delete-modal
      :show.sync="showDeleteConfirmationPopup"
      :on-close="closeDeletePopup"
      :on-confirm="confirmDeletion"
      :title="$t('LABEL_MGMT.DELETE.CONFIRM.TITLE')"
      :message="$t('MACROS.DELETE.CONFIRM.MESSAGE')"
      :message-value="deleteMessage"
      :confirm-text="$t('MACROS.DELETE.CONFIRM.YES')"
      :reject-text="$t('MACROS.DELETE.CONFIRM.NO')"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import accountMixin from 'dashboard/mixins/account.js';
import MacrosTable from './MacrosTable.vue';
import TableFooter from '../../../../components/widgets/TableFooter.vue';
import metaMixin from 'shared/mixins/metaMixin';
import TableHeader from 'dashboard/components/widgets/TableHeader.vue';
import SpinnerHoory from 'shared/components/SpinnerHoory.vue';
import NoResultCard from 'dashboard/components/ui/NoResultCard.vue';

export default {
  components: {
    TableFooter,
    MacrosTable,
    TableHeader,
    SpinnerHoory,
    NoResultCard,
  },
  mixins: [alertMixin, accountMixin, metaMixin],
  data() {
    return {
      showDeleteConfirmationPopup: false,
      selectedResponse: {},
      loading: {},
      perPageCount: 20,
      search: '',
      isSearching: false,
    };
  },
  computed: {
    ...mapGetters({
      records: ['macros/getMacros'],
      uiFlags: 'macros/getUIFlags',
      meta: 'macros/getMeta',
    }),
    deleteMessage() {
      return ` ${this.selectedResponse.name}?`;
    },
  },
  mounted() {
    this.fetchMacros(this.meta.currentPage);
  },
  methods: {
    execApplySearch(data) {
      this.search = data.search;
      this.isSearching = true;
      this.fetchMacros(1);
    },
    fetchMacros(page) {
      this.isSearching = true;
      this.$store.dispatch('macros/get', { page, search: this.search });
      this.isSearching = false;
    },
    openDeletePopup(response) {
      this.showDeleteConfirmationPopup = true;
      this.selectedResponse = response;
    },
    closeDeletePopup() {
      this.showDeleteConfirmationPopup = false;
    },
    onPageChange(page) {
      this.fetchMacros(page);
    },
    redirectToChannelSettings(macro) {
      this.$router.push(
        `${this.addAccountScoping(`settings/macros/${macro.id}/edit`)}`
      );
    },
    confirmDeletion() {
      this.loading[this.selectedResponse.id] = true;
      this.closeDeletePopup();
      this.deleteMacro(this.selectedResponse.id);
    },
    async deleteMacro(id) {
      try {
        await this.$store.dispatch('macros/delete', id);
        this.execUpdateRecordsAndMetadata(
          {
            action: 'delete',
            perPageCount: this.perPageCount,
            record: id,
            moduleName: 'macros',
          },
          () => {
            this.fetchMacros(this.meta.currentPage);
          }
        );
        this.showAlert(this.$t('MACROS.DELETE.API.SUCCESS_MESSAGE'), 'success');
        this.loading[this.selectedResponse.id] = false;
      } catch (error) {
        this.showAlert(this.$t('MACROS.DELETE.API.ERROR_MESSAGE'), 'error');
      }
    },
  },
};
</script>

<style scoped>
.macros__empty-state {
  padding: var(--space-slab);
  height: 100%;
}
</style>
