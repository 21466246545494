<template>
  <router-link
    v-if="isMenuEnabled(to)"
    v-slot="{ href, isActive, navigate }"
    :to="to"
    custom
    active-class="active"
  >
    <li :class="{ active: isActive }">
      <a
        :href="href"
        class="button clear menu-item text-truncate"
        :class="{ 'is-active': isActive, 'text-truncate': shouldTruncate }"
        @click="navigate"
      >
        <span v-if="icon" class="badge--icon">
          <fluent-icon class="inbox-icon" :icon="icon" size="12" />
        </span>
        <span
          v-if="labelColor"
          class="badge--label"
          :style="{ backgroundColor: labelColor }"
        />
        <span
          :title="menuTitle"
          class="menu-label button__content title-wrap"
          :class="{ 'text-truncate': shouldTruncate }"
        >
          <span class="title-wrap__label">{{ label }}</span>
          <span v-if="showChildCount" class="count-view">
            {{ childItemCount }}
          </span>
        </span>
        <span v-if="count" class="badge" :class="{ secondary: !isActive }">
          {{ count }}
        </span>
        <span v-if="warningIcon" class="badge--icon">
          <fluent-icon
            v-tooltip.top-end="$t('SIDEBAR.FACEBOOK_REAUTHORIZE')"
            class="inbox-icon"
            :icon="warningIcon"
            size="12"
          />
        </span>
      </a>
    </li>
  </router-link>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    to: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    labelColor: {
      type: String,
      default: '',
    },
    shouldTruncate: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: '',
    },
    warningIcon: {
      type: String,
      default: '',
    },
    count: {
      type: String,
      default: '',
    },
    showChildCount: {
      type: Boolean,
      default: false,
    },
    childItemCount: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapGetters({
      globalConfig: 'globalConfig/get',
    }),
    showIcon() {
      return { 'text-truncate': this.shouldTruncate };
    },
    menuTitle() {
      return this.shouldTruncate ? this.label : '';
    },
  },
  methods: {
    isMenuEnabled(to) {
      if (to.includes('reports/agents/activity')) {
        return this.isAgentsActivityEnabled();
      }
      return true;
    },
    isAgentsActivityEnabled() {
      return (
        this.globalConfig.enableAgentsActivity === true ||
        this.globalConfig.enableAgentsActivity === 'true'
      );
    },
  },
};
</script>
<style lang="scss" scoped>
$badge-size: var(--space-normal);
$label-badge-size: var(--space-slab);

.button {
  margin: var(--space-small) 0;
}
.title-wrap {
  display: flex;
  align-items: center;
  &__label {
    max-width: 170px;
    text-wrap: wrap;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
.menu-item {
  display: inline-flex;
  color: var(--s-600);
  font-weight: var(--font-weight-medium);
  width: 100%;
  height: var(--space-large);
  padding: var(--space-smaller) var(--space-smaller);
  margin: var(--space-micro) 0;
  text-align: left;
  line-height: 1.2;

  &:hover {
    color: var(--h-700);
    background: var(--white);
    box-shadow: var(--shadow-small);
  }

  &:focus {
    border-color: var(--v-300);
  }

  &.is-active {
    color: var(--v-400);
    border-color: var(--s-25);
    background: var(--white);
    box-shadow: var(--shadow-small);
  }
  &.is-active .count-view {
    background: var(--s-75);
    color: var(--v-400);
  }
}

.menu-label {
  flex-grow: 1;
}

.inbox-icon {
  font-size: var(--font-size-nano);
}

.badge--label,
.badge--icon {
  display: inline-flex;
  border-radius: var(--border-radius-small);
  margin-right: var(--space-smaller);
}

.badge--icon {
  align-items: center;
  height: $badge-size;
  justify-content: center;
  min-width: $badge-size;
}

.badge--label {
  height: $label-badge-size;
  min-width: $label-badge-size;
  margin-left: var(--space-smaller);
  border: 1px solid var(--color-border-light);
}

.badge.secondary {
  min-width: unset;
  background: var(--s-75);
  color: var(--s-600);
  font-weight: var(--font-weight-bold);
}

.count-view {
  background: var(--s-50);
  border-radius: var(--border-radius-normal);
  color: var(--s-600);
  font-size: var(--font-size-micro);
  font-weight: var(--font-weight-bold);
  margin-left: var(--space-smaller);
  padding: var(--space-zero) var(--space-smaller);
  line-height: var(--font-size-small);
}
</style>
