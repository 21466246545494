export default {
  computed: {
    currentState() {
      const fetchingFlag = this.uiFlags.fetchingList || this.uiFlags.isFetching;
      switch (true) {
        case !this.records.length && !this.isSearching && !fetchingFlag:
          return 'noResult';
        case this.isSearching || fetchingFlag:
          return 'loading';
        case !fetchingFlag && this.records.length && !this.isSearching:
          return 'records';
        default:
          return null;
      }
    },
  },
  methods: {
    async execUpdateRecordsAndMetadata(data, cb) {
      let execCb = await this.$store.dispatch('updateRecordsAndMetadata', data);
      if (execCb) cb();
    },
  },
};
