<template>
  <div class="column content-box">
    <woot-button
      color-scheme="success"
      class-names="button--fixed-top"
      icon="add-circle"
      @click="openAddPopup()"
    >
      {{ $t('CANNED_MGMT.HEADER_BTN_TXT') }}
    </woot-button>
    <!-- List Canned Response -->
    <div class="row table-view">
      <div class="small-8 columns with-right-space ">
        <table-header
          :has-search="true"
          :initial-search-value="search"
          @applySearch="execApplySearch"
        />
        <no-result-card
          v-if="currentState === 'noResult'"
          :title="$t('CANNED_MGMT.NOT_FOUND.TITLE')"
        />
        <spinner-hoory v-if="currentState === 'loading'" class="margin-top-2" />
        <template v-if="currentState === 'records'">
          <canned-table
            :items="records"
            :search-text="search"
            @editCanned="openEditPopup"
            @deleteCanned="openDeletePopup"
          />
          <table-footer
            :current-page="Number(meta.currentPage)"
            :total-count="meta.count"
            :page-size="perPageCount"
            :records-count="records.length"
            @page-change="fetchCanneds"
          />
        </template>
      </div>

      <div class="small-4 columns">
        <span v-dompurify-html="$t('CANNED_MGMT.SIDEBAR_TXT')" />
      </div>
    </div>
    <!-- Add Agent -->
    <woot-modal :show.sync="showAddPopup" :on-close="hideAddPopup">
      <add-canned :page-size="perPageCount" :on-close="hideAddPopup" />
    </woot-modal>

    <!-- Edit Canned Response -->
    <woot-modal :show.sync="showEditPopup" :on-close="hideEditPopup">
      <edit-canned
        v-if="showEditPopup"
        :id="selectedResponse.id"
        :edshort-code="selectedResponse.short_code"
        :edcontent="selectedResponse.content"
        :edinboxes="selectedResponse.inboxes"
        :on-close="hideEditPopup"
      />
    </woot-modal>

    <!-- Delete Canned Response -->
    <woot-delete-modal
      :show.sync="showDeleteConfirmationPopup"
      :on-close="closeDeletePopup"
      :on-confirm="confirmDeletion"
      :title="$t('CANNED_MGMT.DELETE.CONFIRM.TITLE')"
      :message="$t('CANNED_MGMT.DELETE.CONFIRM.MESSAGE')"
      :message-value="deleteMessage"
      :confirm-text="deleteConfirmText"
      :reject-text="deleteRejectText"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import AddCanned from './AddCanned';
import EditCanned from './EditCanned';
import urlMixin from 'dashboard/mixins/urlMixin';
import CannedTable from './CannedTable';
import TableFooter from 'dashboard/components/widgets/TableFooter';
import NoResultCard from 'dashboard/components/ui/NoResultCard.vue';
import alertMixin from 'shared/mixins/alertMixin';
import metaMixin from 'shared/mixins/metaMixin';
import TableHeader from 'dashboard/components/widgets/TableHeader.vue';
import SpinnerHoory from 'shared/components/SpinnerHoory.vue';

export default {
  components: {
    NoResultCard,
    AddCanned,
    EditCanned,
    CannedTable,
    TableFooter,
    TableHeader,
    SpinnerHoory,
  },
  mixins: [urlMixin, alertMixin, metaMixin],
  data() {
    return {
      loading: {},
      isSearching: false,
      showAddPopup: false,
      showEditPopup: false,
      showDeleteConfirmationPopup: false,
      selectedResponse: {},
      cannedResponseAPI: {
        message: '',
      },
      search: this.$route.query.search || '',
      perPageCount: 20,
    };
  },
  computed: {
    ...mapGetters({
      records: 'getCannedResponses',
      meta: 'getMeta',
      uiFlags: 'getUIFlags',
    }),
    // Delete Modal
    deleteConfirmText() {
      return `${this.$t('CANNED_MGMT.DELETE.CONFIRM.YES')} ${
        this.selectedResponse.short_code
      }`;
    },
    deleteRejectText() {
      return `${this.$t('CANNED_MGMT.DELETE.CONFIRM.NO')} ${
        this.selectedResponse.short_code
      }`;
    },
    deleteMessage() {
      return ` ${this.selectedResponse.short_code}?`;
    },
  },
  mounted() {
    this.fetchCanneds(this.meta.currentPage);
  },
  created() {
    // this.$store.dispatch('inboxes/get');
  },
  methods: {
    execApplySearch(data) {
      this.search = data.search;
      this.isSearching = true;
      this.fetchCanneds(1);
    },
    fetchCanneds(page) {
      this.isSearching = true;
      this.updatePageParams(page, this.search);
      this.$store.dispatch('getCannedResponse', {
        page,
        search: this.search,
      });
      this.isSearching = false;
    },
    setAlert(message, type) {
      // Reset loading, current selected agent
      this.loading[this.selectedResponse.id] = false;
      this.selectedResponse = {};
      // Show message
      this.cannedResponseAPI.message = message;
      this.showAlert(message, type);
    },
    // Edit Function
    openAddPopup() {
      this.showAddPopup = true;
    },
    hideAddPopup() {
      this.showAddPopup = false;
    },

    // Edit Modal Functions
    openEditPopup(response) {
      this.showEditPopup = true;
      this.selectedResponse = response;
    },
    hideEditPopup() {
      this.showEditPopup = false;
    },

    // Delete Modal Functions
    openDeletePopup(response) {
      this.showDeleteConfirmationPopup = true;
      this.selectedResponse = response;
    },
    closeDeletePopup() {
      this.showDeleteConfirmationPopup = false;
    },
    // Set loading and call Delete API
    confirmDeletion() {
      this.loading[this.selectedResponse.id] = true;
      this.closeDeletePopup();
      this.deleteCannedResponse(this.selectedResponse.id);
    },
    deleteCannedResponse(id) {
      this.$store
        .dispatch('deleteCannedResponse', id)
        .then(() => {
          this.setAlert(
            this.$t('CANNED_MGMT.DELETE.API.SUCCESS_MESSAGE'),
            'success'
          );
          this.execUpdateRecordsAndMetadata(
            {
              action: 'delete',
              perPageCount: this.perPageCount,
              record: id,
              moduleName: 'cannedResponse',
            },
            () => {
              this.fetchCanneds(this.meta.currentPage);
            }
          );
        })
        .catch(error => {
          const errorMessage =
            error?.message || this.$t('CANNED_MGMT.DELETE.API.ERROR_MESSAGE');
          this.setAlert(errorMessage, 'error');
        });
    },
  },
};
</script>
<style scoped>
.short-code {
  width: 14rem;
}
.wrap-break-words {
  word-break: break-all;
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
