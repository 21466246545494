var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"settings--content"},[_c('div',{staticClass:"widget-builder-container"},[_c('div',{staticClass:"settings-container"},[_c('div',{staticClass:"settings-content"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.updateWidget($event)}}},[_c('woot-avatar-uploader',{attrs:{"label":_vm.$t('INBOX_MGMT.WIDGET_BUILDER.WIDGET_OPTIONS.AVATAR.LABEL'),"data-testid":"widget-option-avatar-uploader","src":_vm.avatarUrl,"delete-avatar":""},on:{"change":_vm.handleImageUpload,"onAvatarDelete":_vm.handleAvatarDelete}}),_vm._v(" "),_c('woot-input',{class:{ error: _vm.$v.websiteName.$error },attrs:{"label":_vm.$t(
                'INBOX_MGMT.WIDGET_BUILDER.WIDGET_OPTIONS.WEBSITE_NAME.LABEL'
              ),"placeholder":_vm.$t(
                'INBOX_MGMT.WIDGET_BUILDER.WIDGET_OPTIONS.WEBSITE_NAME.PLACE_HOLDER'
              ),"error":_vm.websiteNameValidationErrorMsg},on:{"blur":_vm.$v.websiteName.$touch},model:{value:(_vm.websiteName),callback:function ($$v) {_vm.websiteName=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"websiteName"}}),_vm._v(" "),_c('woot-input',{attrs:{"label":_vm.$t(
                'INBOX_MGMT.WIDGET_BUILDER.WIDGET_OPTIONS.WELCOME_HEADING.LABEL'
              ),"placeholder":_vm.$t(
                'INBOX_MGMT.WIDGET_BUILDER.WIDGET_OPTIONS.WELCOME_HEADING.PLACE_HOLDER'
              ),"data-testid":"welcome-heading-widget-builder"},model:{value:(_vm.welcomeHeading),callback:function ($$v) {_vm.welcomeHeading=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"welcomeHeading"}}),_vm._v(" "),_c('woot-input',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
              _vm.$t(
                'INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_WELCOME_TAGLINE.PLACEHOLDER'
              )
            ),expression:"\n              $t(\n                'INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_WELCOME_TAGLINE.PLACEHOLDER'\n              )\n            "}],attrs:{"label":_vm.$t(
                'INBOX_MGMT.WIDGET_BUILDER.WIDGET_OPTIONS.WELCOME_TAGLINE.LABEL'
              ),"placeholder":_vm.$t(
                'INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_WELCOME_TAGLINE.PLACEHOLDER'
              ),"data-testid":"welcome-tagline-widget-builder"},model:{value:(_vm.welcomeTagline),callback:function ($$v) {_vm.welcomeTagline=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"welcomeTagline"}}),_vm._v(" "),_c('label',[_vm._v("\n            "+_vm._s(_vm.$t('INBOX_MGMT.WIDGET_BUILDER.WIDGET_OPTIONS.REPLY_TIME.LABEL'))+"\n            "),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.replyTime),expression:"replyTime"}],attrs:{"data-testid":"reply-time-widget-builder"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.replyTime=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},_vm._l((_vm.getReplyTimeOptions),function(option){return _c('option',{key:option.key,domProps:{"value":option.value}},[_vm._v("\n                "+_vm._s(option.text)+"\n              ")])}),0)]),_vm._v(" "),_c('powered-by-configuration',{attrs:{"show-powered-by-setting":_vm.widgetSettings.showPoweredBy},on:{"switch":_vm.handleShowPoweredBy}}),_vm._v(" "),_c('label',[_vm._v("\n            "+_vm._s(_vm.$t(
                'INBOX_MGMT.WIDGET_BUILDER.WIDGET_OPTIONS.WIDGET_COLOR_LABEL'
              ))+"\n            "),_c('woot-color-picker',{model:{value:(_vm.color),callback:function ($$v) {_vm.color=$$v},expression:"color"}})],1),_vm._v(" "),_c('input-radio-group',{attrs:{"name":"widget-bubble-position","label":_vm.$t(
                'INBOX_MGMT.WIDGET_BUILDER.WIDGET_OPTIONS.WIDGET_BUBBLE_POSITION_LABEL'
              ),"items":_vm.widgetBubblePositions.map(function (item) { return (Object.assign({}, item,
                {checked: item.id === _vm.widgetSettings.position})); }),"action":_vm.handleWidgetBubblePositionChange}}),_vm._v(" "),_c('input-radio-group',{attrs:{"name":"widget-bubble-type","label":_vm.$t(
                'INBOX_MGMT.WIDGET_BUILDER.WIDGET_OPTIONS.WIDGET_BUBBLE_TYPE_LABEL'
              ),"items":_vm.widgetBubbleTypes.map(function (item) { return (Object.assign({}, item,
                {checked: item.id === _vm.widgetSettings.type})); }),"action":_vm.handleWidgetBubbleTypeChange}}),_vm._v(" "),(_vm.widgetSettings.type === 'expanded_bubble')?_c('woot-input',{attrs:{"data-testid":"expanded-bubble-title-input","label":_vm.$t(
                'INBOX_MGMT.WIDGET_BUILDER.WIDGET_OPTIONS.WIDGET_BUBBLE_LAUNCHER_TITLE.LABEL'
              ),"placeholder":_vm.$t(
                'INBOX_MGMT.WIDGET_BUILDER.WIDGET_OPTIONS.WIDGET_BUBBLE_LAUNCHER_TITLE.PLACE_HOLDER'
              )},model:{value:(_vm.widgetSettings.launcherTitle),callback:function ($$v) {_vm.$set(_vm.widgetSettings, "launcherTitle", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"widgetSettings.launcherTitle"}}):_vm._e(),_vm._v(" "),_c('input-radio-group',{attrs:{"name":"allow-widget-maximize","label":_vm.$t(
                'INBOX_MGMT.WIDGET_BUILDER.WIDGET_OPTIONS.ALLOW_WIDGET_MAXIMIZE'
              ),"items":_vm.widgetMaximizeOptions.map(function (item) { return (Object.assign({}, item,
                {checked: item.id === _vm.widgetSettings.allowWidgetMaximize})); }),"action":_vm.handleWidgetMaximizeChange}}),_vm._v(" "),_c('div',{staticClass:"special-settings"},[_c('input',{attrs:{"id":"hide_message_bubble","type":"checkbox","value":"hide_message_bubble"},domProps:{"checked":_vm.widgetSettings.hideMessageBubble},on:{"input":_vm.handleHideMessageBubbleChange}}),_vm._v(" "),_c('div',[_c('label',{attrs:{"for":"hide_message_bubble"}},[_vm._v("\n                "+_vm._s(_vm.$t(
                    'INBOX_MGMT.WIDGET_BUILDER.WIDGET_OPTIONS.HIDE_MESSAGE_BUBBLE'
                  ))+"\n              ")]),_vm._v(" "),_c('span',[_vm._v("\n                "+_vm._s(_vm.$t(
                    'INBOX_MGMT.WIDGET_BUILDER.WIDGET_OPTIONS.HIDE_MESSAGE_BUBBLE_DESCRIPTION'
                  ))+"\n              ")])])]),_vm._v(" "),_c('label',{attrs:{"for":"use_browser_language"}},[_c('input',{attrs:{"id":"use_browser_language","type":"checkbox","value":"use_browser_language"},domProps:{"checked":_vm.widgetSettings.useBrowserLanguage},on:{"input":_vm.handleUseBrowserLanguageChange}}),_vm._v("\n            "+_vm._s(_vm.$t(
                'INBOX_MGMT.WIDGET_BUILDER.WIDGET_OPTIONS.USE_BROWSER_LANGUAGE'
              ))+"\n          ")]),_vm._v(" "),_c('label',[_vm._v("\n            "+_vm._s(_vm.$t('INBOX_MGMT.WIDGET_BUILDER.WIDGET_OPTIONS.DEFAULT_LANGUAGE'))+"\n            "),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.widgetSettings.locale),expression:"widgetSettings.locale"}],attrs:{"disabled":_vm.widgetSettings.useBrowserLanguage},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.widgetSettings, "locale", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.getDefaultLanguageOptions),function(option){return _c('option',{key:option.value,domProps:{"value":option.value}},[_vm._v("\n                "+_vm._s(option.text)+"\n              ")])}),0),_vm._v(" "),_c('span',{staticClass:"helper-text"},[_vm._v("\n              "+_vm._s(_vm.$t(
                  'INBOX_MGMT.WIDGET_BUILDER.WIDGET_OPTIONS.DEFAULT_LANGUAGE_DESCRIPTION'
                ))+"\n            ")])]),_vm._v(" "),_c('woot-submit-button',{staticClass:"submit-button",attrs:{"button-text":_vm.$t(
                'INBOX_MGMT.WIDGET_BUILDER.WIDGET_OPTIONS.UPDATE.BUTTON_TEXT'
              ),"loading":_vm.uiFlags.isUpdating,"disabled":_vm.$v.$invalid || _vm.uiFlags.isUpdating}})],1)])]),_vm._v(" "),_c('div',{staticClass:"widget-container"},[(_vm.isWidgetPreview)?_c('div',{staticClass:"widget-preview"},[_c('Widget',{attrs:{"welcome-heading":_vm.welcomeHeading,"welcome-tagline":_vm.welcomeTagline,"website-name":_vm.websiteName,"logo":_vm.avatarUrl,"is-online":"","reply-time":_vm.replyTime,"color":_vm.color,"widget-bubble-position":_vm.widgetSettings.position,"widget-bubble-launcher-title":_vm.widgetSettings.launcherTitle,"widget-bubble-type":_vm.widgetSettings.type,"show-powered-by":_vm.widgetSettings.showPoweredBy}})],1):_c('div',{staticClass:"widget-script"},[_c('woot-code',{attrs:{"script":_vm.widgetScript}})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }